import { render, staticRenderFns } from "./officeAutomation.vue?vue&type=template&id=7bbebbc4&scoped=true&"
import script from "./officeAutomation.vue?vue&type=script&lang=js&"
export * from "./officeAutomation.vue?vue&type=script&lang=js&"
import style0 from "./officeAutomation.vue?vue&type=style&index=0&id=7bbebbc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bbebbc4",
  null
  
)

export default component.exports