/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-01 18:14:35
 * @LastEditTime: 2021-12-24 09:39:01
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/officeAutomation.js
 */

import request from '@/utils/request'; 
 
// 绑定钉钉
export function bindDingTalk(param) {
    return request({
        url: '/system/user/bind/ding-talk',
        method: 'post', 
        data: param
    })
}

// 钉钉审批模板列表 
export function getProcessTemplates(param) {
    return request({
        url: '/admin/ding-talk/process-template/list',
        method: 'post',
        data: param
    })
}

export function processTemplateSync(param) {
    return request({
        url: '/admin/ding-talk/process-template/sync',
        method: 'post',
        data: param
    })
} 

//发起审批实例
export function createProcessInstance(param) {
    return request({
        url: '/admin/ding-talk/create-process-instance',
        method: 'post',
        data: param
    })
}

//  撤销审批实例
export function terminateProcessInstance(param) {
    return request({
        url: '/admin/ding-talk/terminate-process-instance',
        method: 'post',
        data: param
    })
}

//添加审批评论
export function addProcessInstanceComment(param) {
    return request({
        url: '/admin/ding-talk/add-process-instance-comment',
        method: 'post',
        data: param
    })
}

// 同意或拒绝审批任务
export function executeProcessInstance(param) {
    return request({
        url: '/admin/ding-talk/execute-process-instance',
        method: 'post',
        data: param
    })
}
//下载审批附件
export function getProcessInstanceFileUrl(param) {
    return request({
        url: '/admin/ding-talk/get-process-instance-file-url',
        method: 'post',
        data: param
    })
}

//获取审批实例详情
export function getProcessInstance(param) {
    return request({
        url: '/admin/ding-talk/get-process-instance?processInstanceId=' + String(param),
        method: 'get',
    })
}

//获取用户待审批数量
export function getProcessTodoNum(param) {
    return request({
        url: '/admin/ding-talk/get-process-todo-num?userId=' + param ,
        method: 'get',
    })
}

//获取用户待办列表（分页）  （待处理、已处理）
export function getProcessTotoTask(param) {
    return request({
        url: '/admin/ding-talk/get-process-todo-task',
        method: 'post',
        data: param
    })
}


//获取用户审批实例列表（分页）  （我发起的）
export function getProcessInstanceList(param) {
    return request({
        url: '/admin/ding-talk/get-process-instance-list',
        method: 'post',
        data: param
    })
}
//获取用户所有模板的审批实例列表  （我发起的）
export function getAllProcessInstanceList(param) {
    return request({
        url: '/admin/ding-talk/get-all-process-instance-list',
        method: 'post',
        data: param
    })
}

export function getDownloadUri(param) {
    return request({
        url: "/admin/ding-talk/process/file/preview?processInstanceId=" +
        param.processInstanceId +
        "&fileId=" +
        param.fileId +
        "&fileName=" +
        param.fileName,
        method: 'get',

    })
}


//获取待办数量 （首页审批）
export function getHomeTodoNum(param) {
    return request({
        url: '/admin/user-notice/get-todo-num',
        method: 'get',
    })
}

//获取数据权限待审批列表 （首页审批）
export function getDataAuthApplyMyList(param) {
    return request({
        url: '/admin/data-auth-apply/my-list',
        method: 'post',
        data: param
    })
}