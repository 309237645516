<!--
 * @Description: 差旅费报销单
 * @Author: transparent
 * @Date: 2021-11-30 16:48:34
 * @LastEditTime: 2022-07-26 09:58:58
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/travelExpenses.vue
-->


<template>
  <div v-if="show" class="oaComponent flexFJA">
    <el-card class="box-card">
      <el-form
        :model="formData"
        ref="editTaskTemplate"
        :rules="rules"
        class="addDailog"
        onsubmit="return false;"
        label-width="140px"
        label-position="top"
      >
        <el-form-item label="关联审批单 (可多选)" prop="approvalForm">
          <div class="approvalForm">
            <span
              class="one"
              v-for="(item, index) in approvalForm"
              :key="index"
            >
              <span>{{ item.title }}</span>
              <i
                class="el-icon-close"
                @click="deleteApprovalForm(item, index)"
              ></i>
            </span>
          </div>
          <div
            class="associatedApprovalFormIcon flexJA"
            @click="openAssociatedApprovalFormDialog"
          >
            <i class="el-icon-plus"></i>
          </div>
          <associatedApprovalForm
            v-if="associatedApprovalFormDialog"
            :associatedApprovalFormDialog="associatedApprovalFormDialog"
            v-on:listenAssociatedApprovalFormClose="
              listenAssociatedApprovalFormClose
            "
          ></associatedApprovalForm>
        </el-form-item>
        <el-form-item label="出差地点" prop="travellocation">
          <el-input
            type="text"
            v-model="formData.travellocation"
            placeholder="请输入出差地点"
            @change="travellocationChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="beginTime">
          <el-date-picker
            v-model="formData.beginTime"
            type="date"
            placeholder="选择开始时间"
            align="right"
            value-format="yyyy-MM-dd"
            @change="planBeginTimeChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
            v-model="formData.endTime"
            type="date"
            placeholder="选择结束时间"
            align="right"
            value-format="yyyy-MM-dd"
            @change="endTimeChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时长（天）" prop="duration">
          <el-input-number
            v-model="formData.duration"
            :controls="false"
            label="请输入时长（天）"
            style="text-align: left !important"
            @change="durationChange($event)"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="所属项目" prop="project">
          <el-input
            type="text"
            v-model="formData.project"
            placeholder="请输入所属项目"
            @change="projectChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="出差事由" prop="businessTripReasons">
          <el-input
            type="text"
            v-model="formData.businessTripReasons"
            placeholder="请输入出差事由"
            @change="businessTripReasonsChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="同行人员" prop="peerPersonnel">
          <el-input
            type="text"
            v-model="formData.peerPersonnel"
            placeholder="请输入同行人员"
            @change="peerPersonnelChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="往返交通费（元）" prop="roundTripTransportation">
          <el-input-number
            v-model="formData.roundTripTransportation"
            :precision="2"
            :controls="false"
            label="请输入往返交通费（元）"
            style="text-align: left !important"
            @change="roundTripTransportationChange($event)"
          ></el-input-number>
          <!-- <div class="capitalizeNumbers flexFc">
            <span>大写</span>
            <span>{{ formData.capital }}</span>
          </div> -->
        </el-form-item>

        <el-form-item label="往返交通费发票附件" prop="enclosure">
          <!-- 上传文件 -->
          <addFile
            :info="addFileInfo"
            :formIndex="9"
            type="file"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="市内交通费（元）" prop="cityTransportationFee">
          <el-input-number
            v-model="formData.cityTransportationFee"
            :precision="2"
            :controls="false"
            label="请输入市内交通费（元）"
            style="text-align: left !important"
            @change="cityTransportationFeeChange($event)"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="市内交通费发票附件" prop="enclosure">
          <!-- 上传文件 -->
          <addFile
            :info="addFileInfo"
            :formIndex="11"
            type="file"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="住宿费（元）" prop="accommodationFee">
          <el-input-number
            v-model="formData.accommodationFee"
            :precision="2"
            :controls="false"
            label="请输入住宿费（元）"
            style="text-align: left !important"
            @change="accommodationFeeChange($event)"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="住宿费发票附件" prop="">
          <!-- 上传文件 -->
          <addFile
            :info="addFileInfo"
            :formIndex="13"
            type="file"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="餐费（元）" prop="meals">
          <el-input-number
            v-model="formData.meals"
            :precision="2"
            :controls="false"
            label="请输入餐费（元）"
            style="text-align: left !important"
            @change="mealsChange($event)"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="餐费发票附件" prop="">
          <!-- 上传文件 -->
          <addFile
            :info="addFileInfo"
            :formIndex="15"
            type="file"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="其他费用（元）" prop="otherExpenses">
          <el-input-number
            v-model="formData.otherExpenses"
            :precision="2"
            :controls="false"
            label="请输入其他费用（元）"
            style="text-align: left !important"
            @change="otherExpensesChange($event)"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="其他费用发票附件" prop="">
          <!-- 上传文件 -->
          <addFile
            :info="addFileInfo"
            :formIndex="17"
            type="file"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="总金额" prop="totalAmount">
          <el-input-number
            v-model="formData.totalAmount"
            :precision="2"
            :controls="false"
            label="请输入付款总额（人民币）"
            style="text-align: left !important"
            @change="totalAmountChange($event)"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="收款人名称" prop="payeeName">
          <el-input
            type="text"
            v-model="formData.payeeName"
            placeholder="请输入收款人名称"
            @change="payeeNameChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账户" prop="bankAccount">
          <el-input-number
            v-model="formData.bankAccount"
            :controls="false"
            label="请输入银行账户"
            style="text-align: left !important"
            @change="bankAccountChange($event)"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="收款人开户行" prop="depositBank">
          <el-input
            type="text"
            v-model="formData.depositBank"
            placeholder="详细到支行"
            @change="depositBankChange($event)"
          ></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remarks">
          <el-input
            type="text"
            v-model="formData.remarks"
            placeholder="请输入备注"
            @change="remarksChange($event)"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="cancel" round>取消</el-button>
        <el-button type="primary" round @click="sure">提交</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import { createProcessInstance } from "@/api/officeAutomation";
import addFile from "@/components/officeAutomation/addFile";
import { convertLowercaseAmountsToUppercase } from "@/common/amount_conversion";
import associatedApprovalForm from "@/components/officeAutomation/associatedApprovalForm";

export default {
  name: "edit",
  data() {
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error("付款金额不能为空"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("请输入正确的小数位数")); //小数点后两位
      } else {
        callback();
      }
    };
    return {
      // "[{"name":"关联审批单","value":""},{"name":"出差地点","value":"北京（无需审批）"},{"name":"开始时间","value":"2021-12-01"},{"name":"结束时间","value":"2021-12-03"},{"name":"时长（天）","value":3},{"name":"所属项目","value":"项目"},{"name":"出差事由","value":"事由"},{"name":"同行人员","value":"无"},{"name":"往返交通费（元）","value":1000},{"name":"发票附件","value":""},{"name":"市内交通费（元）","value":["https://sy-hermes.oss-cn-beijing.aliyuncs.com/test/project/20211207/7decfc91-5e25-4275-89ac-4f4c8c9c239c.png"]},{"name":"发票附件","value":""},{"name":"住宿费（元）","value":""},{"name":"发票附件","value":""},{"name":"餐费（元）","value":""},{"name":"发票附件","value":""},{"name":"其他费用（元）","value":""},{"name":"发票附件","value":""},{"name":"总金额","value":1200},{"name":"收款人姓名","value":"www"},{"name":"银行账户","value":"6214832156766425"},{"name":"收款人开户行","value":""}]"
      title: "新增",
      formData: {},
      associatedApprovalFormDialog: false,
      approvalForm: null,
      dingTalkForm: [
        //value是Array要转换成String
        {
          name: "关联审批单",
          value: "",
        },
        {
          name: "出差地点",
          value: "",
        },
        {
          name: "开始时间",
          value: "",
        },
        {
          name: "结束时间",
          value: "",
        },
        {
          name: "时长（天）",
          value: "",
        },
        {
          name: "所属项目",
          value: "",
        },
        {
          name: "出差事由",
          value: "",
        },
        {
          name: "同行人员",
          value: "",
        },
        {
          name: "往返交通费（元）",
          value: "",
        },
        {
          name: "往返交通费发票附件",
          value: "",
        },
        {
          name: "市内交通费（元）",
          value: "",
        },
        {
          name: "市内交通费发票附件",
          value: "",
        },
        {
          name: "住宿费（元）",
          value: "",
        },
        {
          name: "住宿费发票附件",
          value: "",
        },
        {
          name: "餐费（元）",
          value: "",
        },
        {
          name: "餐费发票附件",
          value: "",
        },
        {
          name: "其他费用（元）",
          value: "",
        },
        {
          name: "其他费用发票附件",
          value: "",
        },
        {
          name: "总金额",
          value: "",
        },
        {
          name: "收款人姓名",
          value: "",
        },
        {
          name: "银行账户",
          value: "",
        },
        {
          name: "收款人开户行",
          value: "",
        },
        {
          name: "备注",
          value: "",
        },
      ],
      addFileInfo: {
        relevantFile: "员工费用报销",
        type: null,
      },
      rules: {
        approvalForm: [
          { required: false, message: "请选择审批单", trigger: "blur" },
        ],
        travellocation: [
          { required: true, message: "出差地点不能为空", trigger: "blur" },
        ],
        beginTime: [
          { required: true, message: "申请日期不能为空", trigger: "change" },
        ],
        endTime: [
          { required: true, message: "结束时间不能为空", trigger: "change" },
        ],

        duration: [
          { required: true, message: "时长（天）不能为空", trigger: "blur" },
        ],
        project: [
          { required: true, message: "所属项目不能为空", trigger: "blur" },
        ],
        businessTripReasons: [
          { required: true, message: "出差事由不能为空", trigger: "blur" },
        ],
        peerPersonnel: [
          { required: true, message: "同行人员不能为空", trigger: "blur" },
        ],
        roundTripTransportation: [
          {
            required: true,
            message: "往返交通费不能为空",
            trigger: "blur",
          },
        ],
        cityTransportationFee: [
          {
            required: true,
            message: "市内交通费不能为空",
            trigger: "blur",
          },
        ],
        enclosure: [
          { required: true, message: "附件不能为空", trigger: "blur" },
        ],

        totalAmount: [
          { required: true, message: "总金额不能为空", trigger: "blur" },
        ],
        businessTripReasons: [
          { required: true, message: "出差事由不能为空", trigger: "blur" },
        ],

        payeeName: [
          { required: true, message: "收款人名称不能为空", trigger: "blur" },
        ],
        bankAccount: [
          { required: true, message: "收款账号不能为空", trigger: "blur" },
        ],
        depositBank: [
          { required: true, message: "开户行不能为空", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    type: String,
    show: Boolean,
    info: Object,
  },
  watch: {
    info: {
      handler(newVal, old) {
        if (newVal && this.type == "edit") {
        }
      },
      immediate: true,
      deep: true,
    },
    // xiaoxieToDaxieRoundTripTransportation(newValue) {
    //   this.formData.capital = newValue;
    // },
  },
  components: {
    addFile,
    associatedApprovalForm,
  },
  computed: {
    // xiaoxieToDaxieRoundTripTransportation() {
    //   const money = this.formData.roundTripTransportation;
    //   return convertLowercaseAmountsToUppercase(money);
    // },
  },
  created() {},
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("1.差旅费报销单");
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  //   created() {
  //   },

  mounted() {
    console.log("2.差旅费报销单");
     let uInfo = window.localStorage.getItem("userInfo");
    let userInfo = JSON.parse(uInfo);
    
    this.formData.payeeName = userInfo.bankUserName;
    this.dingTalkForm[19].value = userInfo.bankName;
    this.formData.bankAccount = userInfo.bankCardNo;
    this.dingTalkForm[20].value = String(userInfo.bankCardNo);
    this.formData.depositBank = userInfo.bankName;
    this.dingTalkForm[21].value = userInfo.bankName;

  },
  methods: {
    travellocationChange(event) {
      this.dingTalkForm[1].value = event;
    },
    planBeginTimeChange(event) {
      this.dingTalkForm[2].value = event;
    },
    endTimeChange(event) {
      this.dingTalkForm[3].value = event;
    },
    durationChange(event) {
      this.dingTalkForm[4].value = event;
    },
    projectChange(event) {
      this.dingTalkForm[5].value = event;
    },
    businessTripReasonsChange(event) {
      this.dingTalkForm[6].value = event;
    },
    peerPersonnelChange(event) {
      this.dingTalkForm[7].value = event;
    },
    roundTripTransportationChange(event) {
      this.dingTalkForm[8].value = event;
    },
    cityTransportationFeeChange(event) {
      this.dingTalkForm[10].value = event;
    },
    accommodationFeeChange(event) {
      this.dingTalkForm[12].value = event;
    },
    mealsChange(event) {
      this.dingTalkForm[14].value = event;
    },
    otherExpensesChange(event) {
      this.dingTalkForm[16].value = event;
    },
    totalAmountChange(event) {
      this.dingTalkForm[18].value = event;
    },

    payeeNameChange(event) {
      console.log(event);
      this.dingTalkForm[19].value = event;
    },
    bankAccountChange(event) {
      console.log(event);
      this.dingTalkForm[20].value = String(event);
    },
    depositBankChange(event) {
      console.log(event);
      this.dingTalkForm[21].value = event;
    },
    remarksChange(event) {
      console.log(event);
      this.dingTalkForm[22].value = event;
    },
    deleteApprovalForm(item, index) {
      this.approvalForm.splice(index, 1);
      if (this.approvalForm.length > 0) {
        let value = [];
        let list = JSON.parse(JSON.stringify(this.approvalForm));
        list.forEach((item, index) => {
          value.push(item.businessId);
        });
        this.dingTalkForm[0].value = value;
      } else {
        this.dingTalkForm[0].value = "";
      }
    },
    openAssociatedApprovalFormDialog() {
      this.associatedApprovalFormDialog = true;
    },
    listenAssociatedApprovalFormClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      if (type == "sure") {
        this.approvalForm = info;
        let value = [];
        let list = JSON.parse(JSON.stringify(info));
        list.forEach((item, index) => {
          value.push(item.businessId);
        });
        this.dingTalkForm[0].value = value;
      }
      this.associatedApprovalFormDialog = !isClose;
    },
    payNumberHandleChange(value) {
      console.log(value);
    },
    cancel() {
      console.log("cancel");
      this.$emit("listenAddClose", {
        type: "cancel",
        isClose: true,
        source: "travelExpenses",
      });
    },
    sure() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit("listenAddClose", {
        type: "sure",
        isClose: true,
      });
      return false;
      let that = this;
      console.log("sure");
      let info = {
        processCode: that.info.processCode,
        formComponentValues: JSON.stringify(that.dingTalkForm),
      };
      console.log(info);
      createProcessInstance(info).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      const { type, isClose, mold,  } = params;
      if (type == "sure") {
        if (mold == "picture") {
          let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
          this.dingTalkForm[11].value = fileUrls;
        } else {
          let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
          this.dingTalkForm[9].value = fileUrls;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oaComponent {
  padding: 20px 0px;
  width: 100%;
  .box-card {
    width: 60%;
    margin-bottom: 15px;
    .el-form {
      width: 100%;
      .el-form-item {
        //   margin-bottom: 10px;
        /deep/ .el-form--label-top {
          /deep/ .el-form-item__label {
            padding: 0px 0 0px 0;
          }
        }
        .el-input-number {
          width: 100%;
          /deep/ .el-input__inner {
            text-align: left !important;
          }
        }
      }
    }
    .associatedApprovalFormIcon {
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
    }

    /* 步骤条 */
    .steps {
      max-height: 500px;
      overflow: hidden;
    }
    .title_wrap {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      height: 100%;
      //   transform: translateY(-20px);
      .description {
        color: #8f95a1;
        font-size: 14px;
        font-weight: 400;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
      .avatr {
        .userName {
          color: #8f95a1;
          font-size: 14px;
        }
      }
    }
    .dialog-footer {
      margin-top: 20px;
      .el-button {
        width: 140px;
        font-size: 16px;
      }
    }
  }
}
</style>

