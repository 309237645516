<!--
 * @Description: OA
 * @Author: transparent
 * @Date: 2021-11-20 10:51:47
 * @LastEditTime: 2021-12-24 09:52:58
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/officeAutomation.vue
-->
<template>
  <el-main>
    <div class="showError" v-if="showError">
      <el-alert
        title="钉钉审批提交功能正在开发中"
        type="warning"
        description="由于钉钉开放能力的限制，正在开发其他方法保证此功能尽早使用"
        show-icon
        close-text="知道了"
        @close="closeShowError"
      >
      </el-alert>
    </div>
    <div class="isBinddingTalk" v-if="goBindDingTalkShow">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :sm="12" :lg="6">
          <el-result icon="warning" title="警告提示" subTitle="请先去绑定钉钉">
            <template slot="extra">
              <!-- <el-button type="primary" size="medium">返回</el-button> -->

              <el-button
                type="primary"
                style="margin-left: 0px"
                @click="goBindDingTalk()"
                >绑定钉钉</el-button
              >
            </template>
          </el-result>
        </el-col>
      </el-row>
    </div>
    <div class="oaTabs" v-if="tabsShow && !goBindDingTalkShow">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="second">
          <span slot="label">
            <el-badge :value="processTotoNum" 
            :hidden="processTotoNum == 0"
             :max="99"  class="item">
              待处理
            </el-badge>
          </span>
          <div class="s" v-if="tabIndex == '0'">
            <oATable :tabIndex="tabIndex" :status="'0'"></oATable>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已处理" name="third">
          <div class="s" v-if="tabIndex == '1'">
            <oATable :tabIndex="tabIndex" :status="'1'"></oATable>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已发起" name="fourth">
          <div class="s" v-if="tabIndex == '2'">
            <oATable :tabIndex="tabIndex" :status="'-1'"></oATable>
          </div>
        </el-tab-pane>
        <el-tab-pane label="申请入口" name="first">
          <el-collapse v-model="collapseActiveNames" @change="handleChange">
            <el-collapse-item
              :title="item.title"
              :name="item.name"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="list flexFwAc">
                <div
                  class="one flexJfAc"
                  v-for="(menu, index2) in item.menus"
                  :key="index2"
                  @click="openMenu(menu)"
                >
                  <div class="flex icon">
                    <img :src="menu.iconUrl" alt="" />
                  </div>
                  <span class="name">
                    {{ menu.name }}
                  </span>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <!-- <el-tab-pane label="我收到的" name="fifth">
          <div class="s" v-if="tabIndex == '4'">
            <oATable :tabIndex="tabIndex"></oATable>
          </div>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="智能报表" name="sixth">智能报表</el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="other" v-if="!tabsShow && !goBindDingTalkShow">
      <el-page-header @back="goBack" :content="openMenusInfo.name">
      </el-page-header>
      <!-- <el-skeleton :rows="6" animated /> -->
      <publicPayment
        :info="openMenusInfo"
        :show="menusStatus.对公付款申请.show"
        v-if="menusStatus.对公付款申请.show"
        v-on:listenAddClose="listenAddClose"
      ></publicPayment>
      <employeeExpenseReimbursement
        :info="openMenusInfo"
        :show="menusStatus.员工费用报销.show"
        v-if="menusStatus.员工费用报销.show"
        v-on:listenAddClose="listenAddClose"
      ></employeeExpenseReimbursement>
      <travelExpenses
        :info="openMenusInfo"
        :show="menusStatus.差旅费报销单.show"
        v-if="menusStatus.差旅费报销单.show"
        v-on:listenAddClose="listenAddClose"
      ></travelExpenses>
      <reserveFund
        :info="openMenusInfo"
        :show="menusStatus.备用金申请.show"
        v-if="menusStatus.备用金申请.show"
        v-on:listenAddClose="listenAddClose"
      ></reserveFund>
      <sealApplication
        :info="openMenusInfo"
        :show="menusStatus.用印申请.show"
        v-if="menusStatus.用印申请.show"
        v-on:listenAddClose="listenAddClose"
      ></sealApplication>
    </div>
  </el-main>
</template>

<script>
import { getProcessTemplates, getProcessTodoNum } from "@/api/officeAutomation";
import publicPayment from "@/components/officeAutomation/publicPayment";
import employeeExpenseReimbursement from "@/components/officeAutomation/employeeExpenseReimbursement";
import travelExpenses from "@/components/officeAutomation/travelExpenses";
import reserveFund from "@/components/officeAutomation/reserveFund";
import sealApplication from "@/components/officeAutomation/sealApplication";
import oATable from "@/components/officeAutomation/OATable";
export default {
  name: "notFount",
  data() {
    return {
      tabsShow: true,
      activeName: null, //fourth
      collapseActiveNames: ["1"],
      list: [
        {
          title: "财务管理",
          name: "1",
          menus: [
            {
              name: "对公付款申请",
              icon: "",
              component: "publicPayment",
            },
            {
              name: "员工费用报销",
              icon: "",
              component: "employeeExpenseReimbursement",
            },
            {
              name: "差旅费报销单",
              icon: "",
              component: "travelExpenses",
            },
            {
              name: "备用金申请",
              icon: "",
              component: "reserveFund",
            },
          ],
        },
        {
          title: "行政管理",
          name: "2",
          menus: [
            {
              name: "用印申请",
              icon: "",
              component: "sealApplication",
            },
          ],
        },
      ],
      show: true,
      menusStatus: {
        对公付款申请: {
          info: {},
          show: false,
        },
        员工费用报销: {
          info: {},
          show: false,
        },
        差旅费报销单: {
          info: {},
          show: false,
        },
        备用金申请: {
          info: {},
          show: false,
        },
        用印申请: {
          info: {},
          show: false,
        },
      },
      openMenusInfo: {
        name: null,
      },
      status: null, // 状态: 待处理 Pending
      tabIndex: null, //"2"
      goBindDingTalkShow: false,
      userInfo: null,
      processTotoNum: null,
      showError: false,
    };
  },
  components: {
    publicPayment,
    employeeExpenseReimbursement,
    travelExpenses,
    reserveFund,
    sealApplication,
    oATable,
  },
  filters: {},
  computed: {},
  watch: {},
  methods: {
    closeShowError() {
      this.showError = false;
    },
    listenAddClose(params) {
      console.log(params);
      const { type, isClose, source } = params;
      if (type == "sure") {
        this.showError = true;
      } else if (type == "cancel") {
        if (source == "publicPayment") {
          this.menusStatus.对公付款申请.show = false;
          this.tabsShow = true;
        } else if (source == "employeeExpenseReimbursement") {
          this.menusStatus.员工费用报销.show = false;
          this.tabsShow = true;
        } else if (source == "travelExpenses") {
          this.menusStatus.差旅费报销单.show = false;
          this.tabsShow = true;
        } else if (source == "reserveFund") {
          this.menusStatus.备用金申请.show = false;
          this.tabsShow = true;
        } else if (source == "sealApplication") {
          this.menusStatus.用印申请.show = false;
          this.tabsShow = true;
        }
      }
    },
    goBindDingTalk() {
      this.$router.push({
        path: "/personalCenter",
        query: {
          type: "bindDingTalk",
        },
      });
      // this.goBindDingTalkShow = false;
    },
    getProcessTodoNum() {
      getProcessTodoNum(this.userInfo.uid).then((res) => {
        if (res.success) {
          let data = res.data;
          this.processTotoNum = data.todoNum;
        }
      });
    },
    getProcessTemplates() {
      getProcessTemplates({}).then((res) => {
        if (res.success) {
          let data = res.data;
          // let list = []
          this.list[0].menus = data.财务管理;
          this.list[1].menus = data.行政管理;
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      this.tabIndex = String(index);
    },
    handleChange(val) {
      console.log(val);
    },
    openMenu(info) {
      console.log(info.name);
      this.showError = false;
      this.tabsShow = false;
      this.openMenusInfo = info;
      this.menusStatus[info.name].show = true;
    },
    goBack() {
      this.tabsShow = true;
      this.menusStatus[this.openMenusInfo.name].show = false;
      this.showError = false;
    },
  },
  created() {
    this.getProcessTemplates();
  },
  mounted() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    console.log(this.userInfo);
    if (!this.userInfo.dingTalkUnionId) {
      this.goBindDingTalkShow = true;
    } else {
      this.tabIndex = "2";
      this.activeName = "fourth";
      this.getProcessTodoNum();
    }
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    console.log(this.userInfo);
    if (!this.userInfo.dingTalkUnionId) {
      this.goBindDingTalkShow = true;
    } else {
      this.tabIndex = "2";
      this.activeName = "fourth";
      this.getProcessTodoNum();
    }
  },
};
</script>

<style lang='scss' scoped>
.oaTabs {
  .el-tabs {
    /deep/ .el-tabs__item {
      padding: 20px 30px;
      height: 60px;
    }
  }
  .list {
    .one {
      width: 200px;
      height: 60px;
      line-height: 50px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin: 0 10px 20px 0;
      text-align: center;
      cursor: pointer;
      // padding: 5px 0px ;
      span {
        font-size: 15px;
      }
      .icon {
        margin: 0 15px;
        img {
          width: auto;
          height: auto;
          border-radius: 10px;
        }
      }
    }
  }
}
sup.el-badge__content.el-badge__content--undefined {
  // background-color: #c03232 !important;
}
.showError {
  margin-bottom: 15px;
}
</style>

 
