<!--
 * @Description: 备用金申请
 * @Author: transparent
 * @Date: 2021-11-30 16:48:34
 * @LastEditTime: 2022-07-26 10:04:29
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/reserveFund.vue
-->


<template>
  <div v-if="show" class="oaComponent flexFJA">
    <el-card class="box-card">
      <el-form
        :model="formData"
        ref="editTaskTemplate"
        :rules="rules"
        class="addDailog"
        onsubmit="return false;"
        label-width="140px"
        label-position="top"
      >
        <el-form-item label="申请日期" prop="applicationDate">
          <el-date-picker
            v-model="formData.planBeginTime"
            type="date"
            placeholder="选择申请日期"
            align="right"
            value-format="timestamp"
            @change="applicationDateChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请人" prop="applicant">
          <el-input
            type="text"
            v-model="formData.applicant"
            placeholder="请输入申请人"
            @change="applicantChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="department">
          <el-input
            type="text"
            v-model="formData.department"
            placeholder="请输入部门"
            @change="departmentChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="事由" prop="Reason">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            v-model="formData.Reason"
            placeholder="请输入事由"
            @change="reasonChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请金额（元）" prop="applicationAmount">
          <el-input-number
            v-model="formData.applicationAmount"
            :precision="2"
            :controls="false"
            label="请输入申请金额（元）"
            style="text-align: left !important"
            @change="applicationAmountChange($event)"
          ></el-input-number>
          <div class="capitalizeNumbers flexFc">
            <span>大写</span>
            <span>{{ formData.capital }}</span>
          </div>
        </el-form-item>

        <el-form-item label="收款人户名" prop="payeeName">
          <el-input
            type="text"
            v-model="formData.payeeName"
            placeholder="请输入收款人户名"
            @change="payeeNameChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="收款人账号" prop="receiverAccountNumber">
          <el-input-number
            v-model="formData.receiverAccountNumber"
            :controls="false"
            label="请输入收款人账号"
            style="text-align: left !important"
            @change="receiverAccountNumberChange($event)"

          ></el-input-number>
        </el-form-item>
        <el-form-item label="收款开户行" prop="depositBank">
          <el-input
            type="text"
            v-model="formData.depositBank"
            placeholder="详细到支行"
            @change="depositBankChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            type="text"
            v-model="formData.remarks"
            placeholder="请输入备注"
            @change="remarksChange($event)"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="cancel" round>取消</el-button>
        <el-button type="primary" round @click="sure">提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { createProcessInstance } from "@/api/officeAutomation";
import addFile from "@/components/officeAutomation/addFile";
import { convertLowercaseAmountsToUppercase } from "@/common/amount_conversion";

export default {
  name: "edit",
  data() {
    return {
      title: "新增",
      formData: {},
      dingTalkForm: [
        //value是Array要转换成String
        {
          name: "申请日期",
          value: "",
        },
        {
          name: "申请人",
          value: "",
        },
        {
          name: "部门",
          value: "",
        },
        {
          name: "事由",
          value: "",
        },
        {
          name: "申请金额（元）",
          value: "",
        },
        {
          name: "收款人户名",
          value: "",
        },
        {
          name: "收款人账号",
          value: "",
        },
        {
          name: "收款开户行",
          value: "",
        },
        {
          name: "备注",
          value: "",
        },
      ],

      addFileInfo: {
        relevantFile: "对公付款申请",
      },
      rules: {
        approvalForm: [
          { required: false, message: "请选择审批单", trigger: "blur" },
        ],
        applicationDate: [
          { required: true, message: "申请日期不能为空", trigger: "change" },
        ],
        paynumber: [
          {
            type: "number",
            required: false,
            trigger: "blur",
          },
        ],
        paysl: [
          { required: true, message: "付款事由不能为空", trigger: "blur" },
        ],
        paytype: [
          { required: true, message: "付款类型不能为空", trigger: "blur" },
        ],
        name: [
          { required: true, message: "收款人名称不能为空", trigger: "blur" },
        ],
        cardnumber: [
          { required: true, message: "收款账号不能为空", trigger: "blur" },
        ],
        depositBank: [
          { required: true, message: "开户行不能为空", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    type: String,
    show: Boolean,
    info: Object,
  },
  watch: {
    info: {
      handler(newVal, old) {
        if (newVal && this.type == "edit") {
        }
      },
      immediate: true,
      deep: true,
    },
    xiaoxieToDaxieApplicationAmount(newValue) {
      this.formData.capital = newValue;
    },
  },
  computed: {
    xiaoxieToDaxieApplicationAmount() {
      const money = this.formData.applicationAmount;
      return convertLowercaseAmountsToUppercase(money);
    },
  },
  components: {
    addFile,
  },
  created() {},
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("3.对公付款申请");
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  //   created() {
  //   },

  mounted() {
    console.log("3.对公付款申请");
      let uInfo = window.localStorage.getItem("userInfo");
    let userInfo = JSON.parse(uInfo);
   
    this.formData.payeeName = userInfo.bankUserName;
    this.dingTalkForm[4].value = userInfo.bankUserName;
    this.formData.receiverAccountNumber = userInfo.bankCardNo;
    this.dingTalkForm[5].value = String(userInfo.bankCardNo);
    this.formData.depositBank = userInfo.bankName;
    this.dingTalkForm[6].value = userInfo.bankName;

 

  },
  methods: {
    applicationDateChange(event) {
      this.dingTalkForm[0].value = event;
    },
    applicantChange(event) {
      this.dingTalkForm[1].value = event;
    },
    departmentChange(event){
      this.dingTalkForm[2].value = event;
    },
    reasonChange(event){
      this.dingTalkForm[3].value = event;
    },
    applicationAmountChange(event){
      this.dingTalkForm[4].value = event;
    },
    // payNumberHandleChange(value) {
    //   console.log(value);
    // },

    payeeNameChange(event) {
      console.log(event);
      this.dingTalkForm[5].value = event;
    },
    receiverAccountNumberChange(event) {
      console.log(event);
      this.dingTalkForm[6].value = String(event);
    },
    depositBankChange(event) {
      console.log(event);
      this.dingTalkForm[7].value = event;
    },
    remarksChange(event) {
      console.log(event);
      this.dingTalkForm[8].value = event;
    },
    //关闭
    cancel() {
      console.log("cancel");
      this.$emit("listenAddClose", {
        type: "cancel",
        isClose: true,
        source: "reserveFund",
      });
    },
    sure() {
      console.log("sure");
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit("listenAddClose", {
        type: "sure",
        isClose: true,
      });
      return false;
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      console.log(params);
      const { type, isClose } = params;
      if (type == "sure") {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oaComponent {
  padding: 20px 0px;
  width: 100%;
  .box-card {
    width: 60%;
    margin-bottom: 15px;
    .el-form {
      width: 100%;
      .el-form-item {
        //   margin-bottom: 10px;
        /deep/ .el-form--label-top {
          /deep/ .el-form-item__label {
            padding: 0px 0 0px 0;
          }
        }
        .el-input-number {
          width: 100%;
          /deep/ .el-input__inner {
            text-align: left !important;
          }
        }
      }
    }
    .approvalFormIcon {
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    /* 步骤条 */
    .steps {
      max-height: 500px;
      overflow: hidden;
    }
    .title_wrap {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      height: 100%;
      //   transform: translateY(-20px);
      .description {
        color: #8f95a1;
        font-size: 14px;
        font-weight: 400;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
      .avatr {
        .userName {
          color: #8f95a1;
          font-size: 14px;
        }
      }
    }
    .dialog-footer {
      margin-top: 20px;
      .el-button {
        width: 140px;
        font-size: 16px;
      }
    }
  }
}
</style>
