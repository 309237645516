<!--
 * @Description: 员工费用报销
 * @Author: transparent
 * @Date: 2021-11-30 16:55:23
 * @LastEditTime: 2022-07-26 17:42:01
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/employeeExpenseReimbursement.vue
-->


<template>
  <div v-if="show" class="oaComponent flexFJA">
    <el-card class="box-card">
      <el-form
        :model="formData"
        ref="editTaskTemplate"
        :rules="rules"
        class="addDailog"
        onsubmit="return false;"
        label-width="140px"
        label-position="top"
      >
        <el-form-item label="申请日期" prop="applicationDate">
          <el-date-picker
            v-model="formData.applicationDate"
            type="date"
            placeholder="选择申请日期"
            align="right"
            value-format="yyyy-MM-dd"
            @change="applicationDateChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="项目名称" prop="projectName">
          <el-input
            type="text"
            v-model="formData.projectName"
            placeholder="与项目相关，填名称；与项目无关，填其他"
            @change="projectNameChange($event)"
          ></el-input>
        </el-form-item>
        <!-- 表格 -->
        <el-divider></el-divider>
        <div
          class="formLists"
          v-for="(item, index) in formData.formLists"
          :key="index"
        >
          <div class="formListTitle flexJsbAc">
            <h5>表格{{ index + 1 }}</h5>
            <el-button type="text" @click="deleteFormOne(index)"
              >删除</el-button
            >
          </div>
          <el-form-item
            label="报销事由"
            :prop="'formLists.' + index + '.reimbursementReason'"
            :rules="{
              required: true,
              message: '报销事由不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              type="text"
              v-model="item.reimbursementReason"
              placeholder="请输入报销事由"
              @change="reimbursementReasonChange($event, index)"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="报销类型"
            :prop="'formLists.' + index + '.reimbursementType'"
            :rules="{
              required: true,
              message: '报销类型不能为空',
              trigger: 'change',
              type: 'array',
            }"
          >
            <el-select
              v-model="item.reimbursementType"
              multiple
              placeholder="请选择"
              @change="reimbursementTypeChange($event, index)"
              no-data-text="加载中···"
            >
              <el-option
                v-for="val in options"
                :key="val.value"
                :label="val.label"
                :value="val.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报销金额" prop="reimbursementAmount">
            <el-input-number
              v-model="item.reimbursementAmount"
              @change="reimbursementAmountChange($event, index)"
              :precision="2"
              :controls="false"
              label="请输入报销总金额（人民币）"
              style="text-align: left !important"
            ></el-input-number>
          </el-form-item>
        </div>

        <el-button
          class="addFormButton"
          @click="addForm"
          plain
          round
          icon="el-icon-plus"
          >添加</el-button
        >

        <el-form-item label="报销总金额" prop="totalReimbursementAmount">
          <el-input-number
            v-model="formData.totalReimbursementAmount"
            @change="totalReimbursementAmountHandleChange"
            :precision="2"
            :controls="false"
            label="请输入报销总金额（人民币）"
            style="text-align: left !important"
            :disabled="true"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="收款人姓名" prop="payeeName">
          <el-input
            type="text"
            v-model="formData.payeeName"
            placeholder="请输入收款人姓名"
            @change="payeeNameChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="bankAccount">
          <el-input-number
            v-model="formData.bankAccount"
            :controls="false"
            label="请输入银行账号"
            style="text-align: left !important"
            @change="bankAccountChange($event)"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="开户行" prop="depositBank">
          <el-input
            type="text"
            v-model="formData.depositBank"
            placeholder="详细到支行"
            @change="depositBankChange($event)"
          ></el-input>
        </el-form-item>

        <el-form-item label="附件" prop="enclosure">
          <!-- 上传文件 -->
          <addFile
            :info="addFileInfo"
            type="file"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="图片" prop="picture">
          <!-- 上传图片 -->
          <addFile
            :info="addFileInfo"
            type="picture"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            type="text"
            v-model="formData.remarks"
            placeholder="请输入备注"
            @change="remarksChange($event)"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="dialog-footer">
        <el-button @click="cancel" round>取消</el-button>
        <el-button type="primary" round @click="sure">提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { createProcessInstance } from "@/api/officeAutomation";
import addFile from "@/components/officeAutomation/addFile";
import { json } from "body-parser";
export default {
  name: "employeeExpenseReimbursement",
  data() {
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error("付款金额不能为空"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("请输入正确的小数位数")); //小数点后两位
      } else {
        callback();
      }
    };
    return {
      title: "新增",
      formData: {
        formLists: [
          {
            reimbursementReason: null,
            reimbursementType: [],
            reimbursementAmount: null,
          },
        ],
      },

      dingTalkForm: [
        //value是Array要转换成String
        {
          name: "申请日期",
          value: "",
        },
        {
          name: "项目名称",
          value: "",
        },
        {
          name: "表格",
          value: "",
        },
        {
          name: "报销总金额",
          value: "",
        },
        {
          name: "收款人姓名",
          value: "",
        },
        {
          name: "银行账号",
          value: "",
        },
        {
          name: "开户行",
          value: "",
        },
        {
          name: "附件",
          value: "",
        },
        {
          name: "图片",
          value: "",
        },
        {
          name: "备注",
          value: "",
        },
      ],
      options: [
        {
          value: "交通费",
          label: "交通费",
        },
        {
          value: "住宿费",
          label: "住宿费",
        },
        {
          value: "招待费",
          label: "招待费",
        },
        {
          value: "餐饮费",
          label: "餐饮费",
        },
        {
          value: "福利费",
          label: "福利费",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      approvalSuccessData: [
        {
          requestId: "c16374401369f1b5a05f094d651cce74",
          userId: "ff25f286dd5d0b10ba18e779b2977f94",
          userName: "二",
          isApprove: "2人",
          approvalDate: "2020-10-05 23:00:00",
          grade: 1,
        },
        {
          requestId: "c16374401369f1b5a05f094d651cce74",
          userId: "9e22f6feba9411e99092fa163e6c79ee",
          userName: "大",
          isApprove: "1人",
          grade: 2,
        },
      ],
      addFileInfo: {
        relevantFile: "对公付款申请",
      },
      rules: {
        projectName: [
          { required: true, message: "项目名称不能为空", trigger: "blur" },
        ],
        reimbursementReason: [
          { required: true, message: "报销事由不能为空", trigger: "blur" },
        ],
        reimbursementType: [
          { required: true, message: "报销类型不能为空", trigger: "change" },
        ],
        applicationDate: [
          { required: true, message: "申请日期不能为空", trigger: "change" },
        ],

        totalReimbursementAmount: [
          {
            type: "string",
            required: true,
            trigger: "blur",
            validator: validateMoney,
          },
        ],
        payeeName: [
          { required: true, message: "收款人名称不能为空", trigger: "blur" },
        ],
        bankAccount: [
          { required: true, message: "收款账号不能为空", trigger: "blur" },
        ],
        depositBank: [
          { required: true, message: "开户行不能为空", trigger: "blur" },
        ],
        picture: [
          {
            type: "array",
            required: true,
            trigger: "blur",
            // validator: null,
          },
        ],
      },
    };
  },
  props: {
    type: String,
    show: Boolean,
    info: Object,
  },
  watch: {
    info: {
      handler(newVal, old) {
        if (newVal && this.type == "edit") {
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    addFile,
  },

  created() {},
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("2.员工费用报销");
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  //   created() {
  //   },

  mounted() {
    console.log("2.员工费用报销");
    let uInfo = window.localStorage.getItem("userInfo");
    let userInfo = JSON.parse(uInfo);
    this.formData.payeeName = userInfo.bankUserName;
    this.dingTalkForm[4].value = userInfo.bankUserName;
    this.formData.bankAccount = userInfo.bankCardNo;
    this.dingTalkForm[5].value = String(userInfo.bankCardNo);
    this.formData.depositBank = userInfo.bankName;
    this.dingTalkForm[6].value = userInfo.bankName;
  },
  methods: {
    applicationDateChange(event) {
      this.dingTalkForm[0].value = event;
    },
    projectNameChange(event) {
      this.dingTalkForm[1].value = event;
    },
    reimbursementReasonChange(event, index) {
      console.log(event);
      console.log(this.dingTalkForm[2].value);
      if (this.dingTalkForm[2].value == "") {
        this.dingTalkForm[2].value = [];
      }
      if (!this.dingTalkForm[2].value[index]) {
        this.dingTalkForm[2].value[index] = [];
      }
      this.dingTalkForm[2].value[index][0] = {
        name: "报销事由",
        value: String(event),
      };
    },
    reimbursementTypeChange(event, index) {
      console.log(event);
      console.log(index);
      if (this.dingTalkForm[2].value == "") {
        this.dingTalkForm[2].value = [];
      }
      if (!this.dingTalkForm[2].value[index]) {
        this.dingTalkForm[2].value[index] = [];
      }
      this.dingTalkForm[2].value[index][1] = {
        name: "报销类型",
        value: JSON.stringify(event),
      };
    },
    reimbursementAmountChange(event, index) {
      console.log(event);
      console.log(index);
      if (this.dingTalkForm[2].value == "") {
        this.dingTalkForm[2].value = [];
      }
      if (!this.dingTalkForm[2].value[index]) {
        this.dingTalkForm[2].value[index] = [];
      }
      this.dingTalkForm[2].value[index][2] = {
        name: "报销金额",
        value: String(event),
      };
      let totalReimbursementAmountList = [];
      let list = JSON.parse(JSON.stringify(this.formData.formLists)); 
      totalReimbursementAmountList = list.map((x) => x.reimbursementAmount); 
      let totalCost = eval(totalReimbursementAmountList.join("+"));
      this.formData.totalReimbursementAmount = totalCost.toFixed(2);
      this.dingTalkForm[3].value = String(this.formData.totalReimbursementAmount);
      
    },
    totalReimbursementAmountHandleChange(value) {
      console.log(value);
      this.dingTalkForm[3].value = String(value);
    },
    payeeNameChange(event) {
      this.dingTalkForm[4].value = event;
    },
    bankAccountChange(event) {
      this.dingTalkForm[5].value = String(event);
    },
    depositBankChange(event) {
      this.dingTalkForm[6].value = String(event);
    },
    remarksChange(event) {
      this.dingTalkForm[9].value = String(event);
    },
    //关闭
    cancel() {
      console.log("cancel");
      this.$emit("listenAddClose", {
        type: "cancel",
        isClose: true,
        source: "employeeExpenseReimbursement",
      });
    },
    sure() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit("listenAddClose", {
        type: "sure",
        isClose: true,
      });
      return false;
      var that = this;
      console.log("sure");
      let info = {
        processCode: that.info.processCode,
        formComponentValues: JSON.stringify(that.dingTalkForm),
      };
      console.log(info);
      createProcessInstance(info).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      const { type, isClose, mold } = params;
      if (type == "sure") {
        if (mold == "picture") {
          let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
          this.dingTalkForm[8].value = JSON.stringify(fileUrls);
        } else {
          let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
          this.dingTalkForm[7].value = JSON.stringify(fileUrls);
        }
      }
    },
    addForm() {
      this.formData.formLists.push({
        reimbursementReason: null,
        reimbursementType: [],
        reimbursementAmount: null,
      });
    },
    deleteFormOne(index) {
      this.formData.formLists.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.oaComponent {
  padding: 20px 0px;
  width: 100%;
  .box-card {
    width: 60%;
    margin-bottom: 15px;
    .el-form {
      width: 100%;
      .el-form-item {
        //   margin-bottom: 10px;
        /deep/ .el-form--label-top {
          /deep/ .el-form-item__label {
            padding: 0px 0 0px 0;
          }
        }
        .el-input-number {
          width: 100%;
          /deep/ .el-input__inner {
            text-align: left !important;
          }
        }
        .el-select {
          width: 100%;
        }
      }
      .addFormButton {
        width: 150px;
        margin-bottom: 50px;
      }
    }
    .approvalFormIcon {
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    /* 步骤条 */
    .steps {
      max-height: 500px;
      overflow: hidden;
    }
    .title_wrap {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      height: 100%;
      //   transform: translateY(-20px);
      .description {
        color: #8f95a1;
        font-size: 14px;
        font-weight: 400;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
      .avatr {
        .userName {
          color: #8f95a1;
          font-size: 14px;
        }
      }
    }
    .dialog-footer {
      margin-top: 20px;
      .el-button {
        width: 140px;
        font-size: 16px;
      }
    }
  }
}
</style>

