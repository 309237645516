<!--
 * @Description: 用印申请
 * @Author: transparent
 * @Date: 2021-11-30 16:48:34
 * @LastEditTime: 2021-12-09 15:30:07
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/sealApplication.vue
-->


<template>
  <div v-if="show" class="oaComponent flexFJA">
    <el-card class="box-card">
      <el-form
        :model="formData"
        ref="editTaskTemplate"
        :rules="rules"
        class="addDailog"
        onsubmit="return false;"
        label-width="140px"
        label-position="top"
      >
        <el-form-item label="用印部门" prop="printingDepartment">
          <el-button class="addFormButton" plain round icon="el-icon-plus"
            >添加</el-button
          >
        </el-form-item>
        <el-form-item label="经办人" prop="handler">
          <el-button class="addFormButton" plain round icon="el-icon-plus"
            >添加</el-button
          >
        </el-form-item>
        <el-form-item label="日期" prop="date">
          <el-date-picker
            v-model="formData.date"
            type="date"
            placeholder="选择日期"
            align="right"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用印文件名称" prop="fileName">
          <el-input
            type="text"
            v-model="formData.fileName"
            placeholder="请输入用印文件名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="文件份数" prop="documentsNumber">
          <el-input-number
            v-model="formData.documentsNumber"
            @change="payNumberHandleChange"
            :controls="false"
            label="请输入文件份数"
            style="text-align: left !important"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="文件类别" prop="documentCategory">
          <el-select v-model="formData.documentCategory" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="加盖何种印章" prop="seal">
          <el-select v-model="formData.seal" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input
            type="text"
            v-model="formData.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="cancel" round>取消</el-button>
        <el-button type="primary" round @click="sure">提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { createProcessInstance } from "@/api/officeAutomation";
import addFile from "@/components/officeAutomation/addFile";
export default {
  name: "edit",
  data() {
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error("付款金额不能为空"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("请输入正确的小数位数")); //小数点后两位
      } else {
        callback();
      }
    };
    return {
      title: "新增",
      formData: {},
      dingTalkForm: [
        //value是Array要转换成String
        {
          name: "用印部门",
          value: null,
        },
        {
          name: "经办人",
          value: null,
        },
        {
          name: "日期",
          value: null,
        },
        {
          name: "用印文件名称",
          value: null,
        },
        {
          name: "文件份数",
          value: null,
        },
        {
          name: "文件类别",
          value: null,
        },
        {
          name: "加盖何种印章",
          value: null,
        },
        {
          name: "用印文件（附件）",
          value: null,
        },
        {
          name: "备注",
          value: null,
        },
      ],
      approvalSuccessData: [
        {
          requestId: "c16374401369f1b5a05f094d651cce74",
          userId: "ff25f286dd5d0b10ba18e779b2977f94",
          userName: "二",
          isApprove: "2人",
          approvalDate: "2020-10-05 23:00:00",
          grade: 1,
        },
        {
          requestId: "c16374401369f1b5a05f094d651cce74",
          userId: "9e22f6feba9411e99092fa163e6c79ee",
          userName: "大",
          isApprove: "1人",
          grade: 2,
        },
      ],
      addFileInfo: {
        relevantFile: "对公付款申请",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      rules: {
        approvalForm: [
          { required: false, message: "请选择审批单", trigger: "blur" },
        ],
        planBeginTime: [
          { required: true, message: "申请日期不能为空", trigger: "change" },
        ],
        payTime: [
          {
            required: true,
            message: "约定付款日期不能为空",
            trigger: "change",
          },
        ],
        fpTime: [
          {
            required: true,
            message: "发票预计提交日期不能为空",
            trigger: "change",
          },
        ],
        paysl: [
          { required: true, message: "付款事由不能为空", trigger: "blur" },
        ],
        paytype: [
          { required: true, message: "付款类型不能为空", trigger: "blur" },
        ],
        paynumber: [
          {
            type: "string",
            required: true,
            trigger: "blur",
            validator: validateMoney,
          },
        ],
        name: [
          { required: true, message: "收款人名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    type: String,
    show: Boolean,
    info: Object,
  },
  watch: {
    info: {
      handler(newVal, old) {
        if (newVal && this.type == "edit") {
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    addFile,
  },
  created() {},
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("4.用印申请");
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  //   created() {
  //   },

  mounted() {
    console.log("4.用印申请");
  },
  methods: {
    payNumberHandleChange(value) {
      console.log(value);
    },
    cancel() {
      console.log("cancel");
      this.$emit("listenAddClose", {
        type: "cancel",
        isClose: true,
        source: "sealApplication",
      });
    },
    sure() {
      console.log("sure");
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit("listenAddClose", {
        type: "sure",
        isClose: true,
      });
      return false;
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      console.log(params);
      const { type, isClose } = params;
      if (type == "sure") {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oaComponent {
  padding: 20px 0px;
  width: 100%;
  .box-card {
    width: 60%;
    margin-bottom: 15px;
    .el-form {
      width: 100%;
      .el-form-item {
        //   margin-bottom: 10px;
        /deep/ .el-form--label-top {
          /deep/ .el-form-item__label {
            padding: 0px 0 0px 0;
          }
        }
        .el-input-number {
          width: 100%;
          /deep/ .el-input__inner {
            text-align: left !important;
          }
        }
        .el-select {
          width: 100%;
        }
      }
    }
    .approvalFormIcon {
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    /* 步骤条 */
    .steps {
      max-height: 500px;
      overflow: hidden;
    }
    .title_wrap {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      height: 100%;
      //   transform: translateY(-20px);
      .description {
        color: #8f95a1;
        font-size: 14px;
        font-weight: 400;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
      .avatr {
        .userName {
          color: #8f95a1;
          font-size: 14px;
        }
      }
    }
    .dialog-footer {
      margin-top: 20px;
      .el-button {
        width: 140px;
        font-size: 16px;
      }
    }
  }
}
</style>
