<!--
 * @Description: 对公付款申请
 * @Author: transparent
 * @Date: 2021-11-30 16:48:34
 * @LastEditTime: 2022-07-22 15:53:24
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/publicPayment.vue
-->

<template>
  <div v-if="show" class="oaComponent flexFJA">
    <el-card class="box-card">
      <el-form
        :model="formData"
        ref="editTaskTemplate"
        :rules="rules"
        class="addDailog"
        onsubmit="return false;"
        label-width="140px"
        label-position="top"
      >
        <el-form-item label="关联审批单 (可多选)" prop="associatedApprovalForm">
          <div class="approvalForm">
            <span
              class="one"
              v-for="(item, index) in approvalForm"
              :key="index"
            >
              <span>{{ item.title }}</span>
              <i
                class="el-icon-close"
                @click="deleteApprovalForm(item, index)"
              ></i>
            </span>
          </div>
          <div
            class="associatedApprovalFormIcon flexJA"
            @click="openAssociatedApprovalFormDialog"
          >
            <i class="el-icon-plus"></i>
          </div>
          <associatedApprovalForm
            v-if="associatedApprovalFormDialog"
            :associatedApprovalFormDialog="associatedApprovalFormDialog"
            v-on:listenAssociatedApprovalFormClose="
              listenAssociatedApprovalFormClose
            "
          ></associatedApprovalForm>
        </el-form-item>
        <el-form-item label="申请日期" prop="applicationDate">
          <el-date-picker
            v-model="formData.applicationDate"
            type="date"
            placeholder="选择申请日期"
            align="right"
            value-format="yyyy-MM-dd"
            @change="applicationDateChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="付款事由" prop="paymentReason">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 5 }"
            v-model="formData.paymentReason"
            placeholder="请输入付款事由"
            @change="paymentReasonChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="付款类型" prop="paymentType">
          <el-input
            type="text"
            v-model="formData.paymentType"
            placeholder="请输入付款类型"
            @change="paymentTypeChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="付款金额" prop="paymentAmount">
          <el-input-number
            v-model="formData.paymentAmount"
            @change="paymentAmountHandleChange"
            :precision="2"
            :controls="false"
            label="请输入付款总额（人民币）"
            style="text-align: left !important"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="约定付款日期" prop="appointmentPaymentDate">
          <el-date-picker
            v-model="formData.appointmentPaymentDate"
            type="date"
            placeholder="选择约定付款日期"
            align="right"
            value-format="yyyy-MM-dd"
            @change="appointmentPaymentDateChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="收款人名称" prop="payeeName">
          <el-input
            type="text"
            v-model="formData.payeeName"
            placeholder="请输入收款人名称"
            @change="payeeNameChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item label="收款账号" prop="collectionAccountNumber">
          <el-input-number
            v-model="formData.collectionAccountNumber"
            :controls="false"
            label="请输入收款账号"
            style="text-align: left !important"
            @change="collectionAccountNumberChange($event)"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="开户行" prop="depositBank">
          <el-input
            type="text"
            v-model="formData.depositBank"
            placeholder="详细到支行"
            @change="depositBankChange($event)"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="发票预计提交日期"
          prop="estimatedInvoiceSubmissionDate"
        >
          <el-date-picker
            v-model="formData.estimatedInvoiceSubmissionDate"
            type="date"
            placeholder="选择发票预计提交日期"
            align="right"
            value-format="yyyy-MM-dd"
            @change="estimatedInvoiceSubmissionDateChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="附件" prop="enclosure">
          <!-- 上传文件 -->
          <addFile
            :info="addFileInfo"
            type="file"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="图片" prop="picture">
          <!-- 上传图片 -->
          <addFile
            :info="addFileInfo"
            type="picture"
            v-on:listenAddFileClose="listenAddFileClose"
          ></addFile>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            type="text"
            v-model="formData.remarks"
            placeholder="请输入备注"
            @change="remarksChange($event)"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="dialog-footer">
        <el-button @click="cancel" round>取消</el-button>
        <el-button type="primary" round @click="sure">提交</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  createProcessInstance,
  processTemplateSync,
} from "@/api/officeAutomation";
import addFile from "@/components/officeAutomation/addFile";
import associatedApprovalForm from "@/components/officeAutomation/associatedApprovalForm";
export default {
  name: "edit",
  data() {
    const validateMoney = (rule, value, callback) => {
      if (!value) {
        callback(new Error("付款金额不能为空"));
      } else if (value.indexOf(".") != -1 && value.split(".").length > 2) {
        callback(new Error("请输入正确格式的金额")); //防止输入多个小数点
      } else if (value.indexOf(".") != -1 && value.split(".")[1].length > 2) {
        callback(new Error("请输入正确的小数位数")); //小数点后两位
      } else {
        callback();
      }
    };
    const validateEnclosure = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请上传附件"));
      } else {
        callback();
      }
    };
    return {
      title: "新增",
      formData: {
        processCode: null,
      },
      dingTalkForm: [
        //value是Array要转换成String
        {
          name: "关联审批单",
          value: "",
        },
        {
          name: "申请日期",
          value: "",
        },
        {
          name: "付款事由",
          value: "",
        },
        {
          name: "付款类型",
          value: "",
        },
        {
          name: "付款金额",
          value: "",
        },
        {
          name: "约定付款日期",
          value: "",
        },
        {
          name: "收款人名称",
          value: "",
        },
        {
          name: "收款账号",
          value: "",
        },
        {
          name: "开户行",
          value: "",
        },
        {
          name: "发票预计提交日期",
          value: "",
        },
        {
          name: "附件",
          value: "",
        },
        {
          name: "图片",
          value: "",
        },
        {
          name: "备注",
          value: "",
        },
      ],
      approvalSuccessData: [
        {
          requestId: "c16374401369f1b5a05f094d651cce74",
          userId: "ff25f286dd5d0b10ba18e779b2977f94",
          userName: "二",
          isApprove: "2人",
          approvalDate: "2020-10-05 23:00:00",
          grade: 1,
        },
        {
          requestId: "c16374401369f1b5a05f094d651cce74",
          userId: "9e22f6feba9411e99092fa163e6c79ee",
          userName: "大",
          isApprove: "1人",
          grade: 2,
        },
      ],
      addFileInfo: {
        relevantFile: "对公付款申请",
      },
      rules: {
        associatedApprovalForm: [
          { required: false, message: "请选择审批单", trigger: "blur" },
        ],
        applicationDate: [
          { required: true, message: "申请日期不能为空", trigger: "change" },
        ],
        appointmentPaymentDate: [
          {
            required: true,
            message: "约定付款日期不能为空",
            trigger: "change",
          },
        ],
        estimatedInvoiceSubmissionDate: [
          {
            required: true,
            message: "发票预计提交日期不能为空",
            trigger: "change",
          },
        ],
        paymentReason: [
          { required: true, message: "付款事由不能为空", trigger: "blur" },
        ],
        paymentType: [
          { required: true, message: "付款类型不能为空", trigger: "blur" },
        ],
        paymentAmount: [
          {
            required: true,
            message: "付款金额不能为空",
            trigger: "blur",
          },
        ],
        payeeName: [
          { required: true, message: "收款人名称不能为空", trigger: "blur" },
        ],
        collectionAccountNumber: [
          { required: true, message: "收款账号不能为空", trigger: "blur" },
        ],
        depositBank: [
          { required: true, message: "开户行不能为空", trigger: "blur" },
        ],
        enclosure: [
          {
            type: "Array",
            required: true,
            trigger: "blur",
            validator: validateEnclosure,
          },
        ],
      },
      associatedApprovalFormDialog: false,
      approvalForm: null,
    };
  },
  props: {
    type: String,
    show: Boolean,
    info: Object,
  },
  watch: {
    info: {
      handler(newVal, old) {
        if (newVal) {
          this.formData.processCode = this.info.processCode;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    addFile,
    associatedApprovalForm,
  },
  created() {},
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log("0.对公付款申请");
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  //   created() {
  //   },

  mounted() {
    console.log("0.对公付款申请");
    // this.processTemplateSync()
  },
  methods: {
    deleteApprovalForm(item, index) {
      this.approvalForm.splice(index, 1);
      if (this.approvalForm.length > 0) {
        let value = [];
        let list = JSON.parse(JSON.stringify(this.approvalForm));
        list.forEach((item, index) => {
          value.push(item.businessId);
        });
        this.dingTalkForm[0].value = value;
      } else {
        this.dingTalkForm[0].value = "";
      }
    },
    applicationDateChange(event) {
      console.log(event);
      this.dingTalkForm[1].value = event;
    },
    paymentReasonChange(event) {
      console.log(event);
      this.dingTalkForm[2].value = event;
    },
    paymentTypeChange(event) {
      console.log(event);
      this.dingTalkForm[3].value = event;
    },
    paymentAmountHandleChange(event) {
      console.log(event);
      this.dingTalkForm[4].value = String(event);
    },
    appointmentPaymentDateChange(event) {
      console.log(event);
      this.dingTalkForm[5].value = event;
    },
    payeeNameChange(event) {
      console.log(event);
      this.dingTalkForm[6].value = event;
    },
    collectionAccountNumberChange(event) {
      console.log(event);
      this.dingTalkForm[7].value = String(event);
    },
    depositBankChange(event) {
      console.log(event);
      this.dingTalkForm[8].value = event;
    },
    estimatedInvoiceSubmissionDateChange(event) {
      console.log(event);
      this.dingTalkForm[9].value = event;
    },
    remarksChange(event) {
      console.log(event);
      this.dingTalkForm[12].value = event;
    },
    processTemplateSync() {
      processTemplateSync({}).then((res) => {
        if (res.success) {
          console.log(res.data);
        }
      });
    },
    openAssociatedApprovalFormDialog() {
      this.associatedApprovalFormDialog = true;
    },
    listenAssociatedApprovalFormClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      if (type == "sure") {
        this.approvalForm = info;
        let value = [];
        let list = JSON.parse(JSON.stringify(info));
        list.forEach((item, index) => {
          value.push(item.businessId);
        });
        this.dingTalkForm[0].value = value;
      }
      this.associatedApprovalFormDialog = !isClose;
    },
    //关闭
    cancel() {
      console.log("cancel");
       this.$emit("listenAddClose", {
          type: "cancel",
          isClose: true,
          source: "publicPayment",
        });
    },
    sure() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$emit("listenAddClose", {
        type: "sure",
        isClose: true,
      });
      return false;
      let that = this;
      console.log("sure");
      let info = {
        processCode: that.info.processCode,
        formComponentValues: JSON.stringify(that.dingTalkForm),
      };
      console.log(info);
      createProcessInstance(info).then((response) => {
        if (response.success) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
        const { type, isClose, mold } = params;
      if (type == "sure") {
        if (mold == "picture") {
          let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
          this.dingTalkForm[11].value = fileUrls;
        }else {
           let fileUrls = [];
          let file = JSON.parse(JSON.stringify(params.files));
          file.forEach((item, index) => {
            fileUrls.push(item.url);
          });
          console.log(fileUrls);
          this.dingTalkForm[10].value = fileUrls;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oaComponent {
  padding: 20px 0px;
  width: 100%;
  .box-card {
    width: 60%;
    margin-bottom: 15px;
    .el-form {
      width: 100%;
      .el-form-item {
        //   margin-bottom: 10px;
        /deep/ .el-form--label-top {
          /deep/ .el-form-item__label {
            padding: 0px 0 0px 0;
          }
        }
        .el-input-number {
          width: 100%;
          /deep/ .el-input__inner {
            text-align: left !important;
          }
        }
      }
    }
    .approvalForm {
      .one {
        background-color: #f2f3f7;
        opacity: 0.8;
        margin-bottom: 10px;
        padding: 10px 20px;
        span {
          color: #000;
        }
        .el-icon-close {
          margin-left: 20px;
        }
      }
    }
    .associatedApprovalFormIcon {
      width: 50px;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
    }
    /* 步骤条 */
    .steps {
      max-height: 500px;
      overflow: hidden;
    }
    .title_wrap {
      display: flex;
      justify-content: space-between;
      //   align-items: center;
      width: 100%;
      height: 100%;
      //   transform: translateY(-20px);
      .description {
        color: #8f95a1;
        font-size: 14px;
        font-weight: 400;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
      .avatr {
        .userName {
          color: #8f95a1;
          font-size: 14px;
        }
      }
    }
    .dialog-footer {
      margin-top: 20px;
      .el-button {
        width: 140px;
        font-size: 16px;
      }
    }
  }
}
</style>
