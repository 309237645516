<!--
 * @Description: 关联审批单
 * @Author: transparent
 * @Date: 2021-12-06 16:57:25
 * @LastEditTime: 2021-12-07 10:39:49
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/associatedApprovalForm.vue
-->

<template>
  <el-dialog
    :title="title"
    width="700px"
    :visible="associatedApprovalFormDialog"
    @close="closeDialog"
    append-to-body
    class="container_lefts"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        :label="item.label"
        :name="item.name"
        v-for="(item, index) in tabs"
        :key="index"
      ></el-tab-pane>
    </el-tabs>
    <el-table
      style="width: 100%; border: none"
      :data="tableData"
      ref="singleTable"
      v-loading="tableLoading" 
      @selection-change="handleSelectionChange"
      :highlight-current-row="true"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="信息" prop="" width="500">
        <template slot-scope="scope">
          <h4>{{ scope.row.title }}</h4>
          <p>申请日期：{{ scope.row.formComponentValues[0].value }}</p>
          <p>项目名称：{{ scope.row.formComponentValues[1].value }}</p>
          <p>收款人姓名：{{ scope.row.formComponentValues[4].value }}</p>
          <p>{{ scope.row.createTime | formatDateFilter }}</p>
        </template>
      </el-table-column>

      <el-table-column label="流程状态" prop="">
        <template slot-scope="scope">
          <span>{{ scope.row.finishTime | formatDateFilter }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button @click="sure" type="primary">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  getProcessTotoTask,
  getAllProcessInstanceList,
  getProcessInstance,
} from "@/api/officeAutomation";
import { formatDate } from "@/common/date";

export default {
  name: "associatedApprovalForm",

  data() {
    return {
      title: "关联审批单",
      status: -1,
      tableLoading: false,
      tabs: [
        {
          label: "我审批的",
          name: "first",
        },
        {
          label: "我发起的",
          name: "second",
        },
      ],
      activeName: "second",
      tableData: [],
      pageNum: 0,
      pageSize: 10,
      multipleSelection: null,
      selectionList: [],
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  components: {},
  props: {
    associatedApprovalFormDialog: Boolean,
  },
  created() {},
  mounted() {
    this.getProcessTotoTask(this.pageNum, this.pageSize);
  },

  watch: {},

  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let list = JSON.parse(JSON.stringify(val));
      //   let multipleSelectionId
      list.forEach((item, index) => {
        this.selectionList.push({
          businessId: item.businessId,
          title: item.title,
        });
      });
    },
    handleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      if (index == 0) {
        this.status = 0;
      } else {
        this.status = -1;
      }
      this.getProcessTotoTask(this.pageNum, this.pageSize);
    },
    getProcessTotoTask(num, pageSize) {
      this.tableData = [];

      this.tableLoading = true;
      if (this.status == 0) {
        let query = {};
        query.offset = num;
        query.count = pageSize;
        query.status = Number(this.status);
        getProcessTotoTask(query).then((response) => {
          if (response.list != null) {
            this.tableData = response.list;
          }
          this.tableLoading = false;
        });
      } else {
        let query = {};
        query.startTime = "1638688700000";
        query.endTime = null;
        getAllProcessInstanceList(query).then((response) => {
          if (response.data.list != null) {
            this.tableData = response.data.list;
          }
          this.tableLoading = false;
        });
      }
    },
    sure() { 
      this.$emit("listenAssociatedApprovalFormClose", {
        type: "sure",
        isClose: true,
        info: this.selectionList,
      });
    },
    closeDialog() {
      this.$emit("listenAssociatedApprovalFormClose", {
        type: "cancel",
        isClose: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.memberList {
  padding: 5px;

  i {
    margin-left: 3px;
  }
}

.el-form {
  .el-form-item {
    width: 100%;

    .el-input {
      width: 100% !important;
    }

    .el-select {
      width: 100%;
    }

    .el-date-picker {
      width: 100% !important;
    }
  }
}

.container_lefts .el-table td,
.container_lefts .el-table th.is-leaf,
.container_lefts .el-table--border,
.container_lefts .el-table--group {
  border: none;
  cursor: pointer;
}
.container_lefts .el-table::before {
  height: 0;
}
.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border: none;
  cursor: pointer;
}
.el-table::before {
  height: 0;
}
</style>
