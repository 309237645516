import request from '@/utils/request';
//分页条件查询项目流程节点列表（带任务）
export function getWorkflowQueryList(param){
    return request({
        url: '/admin/project/workflow/detail/queryList',
        method: 'post',
        data: param
    })
}
//分页条件查找任务列表
export function getTaskQueryList(param){
    return request({
        url: '/admin/task/queryList',
        method: 'post',
        data: param
    })
}
//
//项目流程详情操作处理
export function handleProjectWorkflow(param){
    return request({
        url: '/admin/project/workflow/detail/handle',
        method: 'post',
        data: param
    })
}

//添加任务 start
//条件查找任务模板列表
export function getTaskTemplateList(param){
    return request({
        url: '/admin/task/template/findList',
        method: 'post',
        data: param
    })
}

//增加一个任务
export function addTask(param){
    return request({
        url: '/admin/task/add',
        method: 'post',
        data: param
    })
}
//删除一个任务
export function deleteTask(param){
    return request({
        url: '/admin/task/delete',
        method: 'post',
        data: param
    })
}
//编辑一个任务
export function editTask(param){
    return request({
        url: '/admin/task/edit',
        method: 'post',
        data: param
    })
}
//暂停任务/恢复任务
export function pauseTask(param){
    return request({
        url: '/admin/task/pause',
        method: 'post',
        data: param
    })
}
//取消任务/重新开启任务
export function cancelTask(param){
    return request({
        url: '/admin/task/cancel',
        method: 'post',
        data: param
    })
}
//条件查询单个任务详情
export function findDetailTask(param){
    return request({
        url: '/admin/task/findDetail',
        method: 'post',
        data: param
    })
}

//
export function taskStatusEdit(param){
    return request({
        url: '/admin/task/status/edit',
        method: 'post',
        data: param
    })
}
//添加任务 end

//条件查询任务的下拉列表
export function getTaskSelectList(param){
    return request({
        url: '/admin/task/selectList',
        method: 'post',
        data: param
    })
}


//条件查找流程信息下的任务数量列表
export function getWorkflowInfoTaskList(param){
    return request({
        url: '/admin/task/workflow-info-num-info-list',
        method: 'post',
        data: param
    })
}
//条件查找任务信息（包含项目、流程等）列表
export function getTaskInfoList(param){
    return request({
        url: '/admin/task/info-list',
        method: 'post',
        data: param
    })
}

export function getTaskManagementPagingList(param) {
    return request({
      url: '/admin/task/task-management-paging-list',
      method: 'post',
      data: param
    })
}

//非节点任务 start

export function addTask1(param) {
    return request({
        url: '/admin/task/add1',
        method: 'post',
        data: param
    })
}
export function editTask1(param) {
    return request({
        url: '/admin/task/edit1',
        method: 'post',
        data: param
    })
}
//非节点任务 end


//  admin/task/queryList1
export function getTaskQueryList1(param) {
    return request({
        url: '/admin/task/queryList1',
        method: 'post',
        data: param
    })
}

//查找我参与的项目
export function findUserProjectList(param) {
  return request({
    url: '/admin/project/find-my-projects',
    method: 'post',
    data: param
  })
}

export function findMyProjectWorkflowList(param) {
  return request({
    url: '/admin/project/workflow/find-my-project-workflow-list',
    method: 'post',
    data: param
  })
}

export function findMyProjectWorkflowMembers(param) {
  return request({
    url: '/admin/project/workflow/find-my-projects-members',
    method: 'post',
    data: param
  })
}
