 <!--
 * @Description:
 * @Author: transparent
 * @Date: 2021-12-02 15:31:50
 * @LastEditTime: 2022-08-15 14:52:52
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/components/officeAutomation/OATable.vue
-->

<template>
  <div>
    <el-main>
      <el-form
        v-model="todoTaskQuery"
        class="projectForm"
        label-width="110px"
        :inline="true"
        v-show="tabIndex == '2'"
      >
        <el-form-item label="开始时间" prop="">
          <el-date-picker
            v-model="todoTaskQuery.startTime"
            type="date"
            placeholder="选择日期"
            align="right"
            :picker-options="pickerOptions"
            class="childWidth"
            value-format="timestamp"
            @change="startTimeChange($event)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="">
          <el-date-picker
            v-model="todoTaskQuery.endTime"
            type="date"
            placeholder="选择日期"
            align="right"
            :picker-options="pickerOptions"
            class="childWidth"
            value-format="timestamp"
            @change="endTimeChange($event)"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div class="" v-if="status0">
        <el-table
          style="width: 100%"
          :data="tableData"
          ref="singleTable"
          v-loading="tableLoading"
          @current-change="tableCurrentChange"
          @row-click="rowClick"
          :highlight-current-row="true"
        >
          <el-table-column label="标题" prop="title"></el-table-column>
          <el-table-column label="摘要" prop="">
            <template slot-scope="scope">
              <p>
                {{ scope.row.forms[0].title }}：{{ scope.row.forms[0].content }}
              </p>
              <p>
                {{ scope.row.forms[1].title }}：{{ scope.row.forms[1].content }}
              </p>
              <p>
                {{ scope.row.forms[2].title }}：{{ scope.row.forms[2].content }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="发起人" prop="">
            <template slot-scope="scope">
              <p>{{ scope.row.title | titleFilter }}</p>
            </template>
          </el-table-column>
          <el-table-column label="发起时间" prop="createDate">
            <template slot-scope="scope">
              <span v-show="scope.row.instance.createTime">{{
                scope.row.instance.createTime | formatDateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="完成时间" prop="finishTime">
            <template slot-scope="scope">
              <span v-show="scope.row.instance.finishTime">{{
                scope.row.instance.finishTime | formatDateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="流程状态" prop="">
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.instance.result == 'agree' ||
                  scope.row.instance.result == ''
                "
                :class="{
                  wait: scope.row.instance.status == 'RUNNING',
                  adopt: scope.row.instance.status == 'COMPLETED',
                }"
                >{{
                  scope.row.instance.status == "NEW"
                    ? "新创建"
                    : scope.row.instance.status == "RUNNING"
                    ? "处理中"
                    : scope.row.instance.status == "TERMINATED"
                    ? "已撤销"
                    : scope.row.instance.status == "COMPLETED"
                    ? "审批通过"
                    : scope.row.instance.status == "CANCELED"
                    ? "审批取消"
                    : ""
                }}</span
              >
              <span v-else-if="scope.row.instance.result == 'refuse'"
                >审批拒绝</span
              >
            </template>
          </el-table-column>
        </el-table>

        <div
          class="load flexJA"
          v-show="tableData.length > 0 && hasMore"
          @click="load"
        >
          点击加载更多
        </div>
        <!-- <div class="page-info-class">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.pageNum"
            :page-sizes="[5, 10, 20, 30, 50]"
            :page-size="this.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total"
          ></el-pagination>
        </div> -->
      </div>
      <div class="" v-else>
        <el-table
          style="width: 100%"
          :data="tableData"
          ref="singleTable"
          v-loading="tableLoading"
          @current-change="tableCurrentChange"
          @row-click="rowClick"
          :highlight-current-row="true"
        >
          <el-table-column label="标题" prop="title"></el-table-column>
          <el-table-column label="摘要" prop="" min-width="100px">
            <template slot-scope="scope">
              <div
                class=""
                v-if="scope.row.title.split('的')[1] == '对公付款申请'"
              >
                <p>申请日期：{{ scope.row.formComponentValues[1].value }}</p>
                <p>付款事由：{{ scope.row.formComponentValues[2].value }}</p>
                <p>付款类型：{{ scope.row.formComponentValues[3].value }}</p>
              </div>
              <div
                class=""
                v-if="scope.row.title.split('的')[1] == '员工费用报销'"
              >
                <p>申请日期：{{ scope.row.formComponentValues[0].value }}</p>
                <p>项目名称：{{ scope.row.formComponentValues[1].value }}</p>
                <p>收款人姓名：{{ scope.row.formComponentValues[4].value }}</p>
              </div>
              <div v-if="scope.row.title.split('的')[1] == '请假'">
                <p>
                  请假类型：{{
                    scope.row.formComponentValues[0].value | jsonArrayFilter(4)
                  }}
                </p>
                <p>
                  开始时间：{{
                    scope.row.formComponentValues[0].value | jsonArrayFilter(0)
                  }}
                </p>
                <p>
                  结束时间：{{
                    scope.row.formComponentValues[0].value | jsonArrayFilter(1)
                  }}
                </p>
                <p>
                  时长（小时）：{{
                    scope.row.formComponentValues[0].value | jsonArrayFilter(2)
                  }}
                </p>
                <p>请假事由：{{ scope.row.formComponentValues[1].value }}</p>
              </div>
              <div v-if="scope.row.title.split('的')[1] == '外出'">
                <p>
                  开始时间：{{
                    scope.row.formComponentValues[4].value | jsonArrayFilter(0)
                  }}
                </p>
                <p>
                  结束时间：{{
                    scope.row.formComponentValues[4].value | jsonArrayFilter(1)
                  }}
                </p>
                <p>
                  时长（小时）：{{
                    scope.row.formComponentValues[4].value | jsonArrayFilter(2)
                  }}
                </p>
                <p>外出事由：{{ scope.row.formComponentValues[2].value }}</p>
                <p>同行人：{{ scope.row.formComponentValues[1].value }}</p>
              </div>
              <div v-if="scope.row.title.split('的')[1] == '外出1'">
                <p>开始时间：{{ scope.row.formComponentValues[0].value }}</p>
                <p>结束时间：{{ scope.row.formComponentValues[1].value }}</p>
                <p>
                  时长（小时）：{{ scope.row.formComponentValues[2].value }}
                </p>
                <p>外出事由：{{ scope.row.formComponentValues[3].value }}</p>
                <p>同行人：{{ scope.row.formComponentValues[4].value }}</p>
              </div>
              <div v-if="scope.row.title.split('的')[1] == '出差1'">
                <p>
                  出差事由：{{
                    scope.row.formComponentValues[0].value | nullFilter
                  }}
                </p>
                <p>
                  出差时长(小时)：{{ scope.row.formComponentValues[2].value }}
                </p>
                <p>
                  出差备注：{{
                    scope.row.formComponentValues[3].value | nullFilter
                  }}
                </p>
                <p>
                  同行人：{{
                    scope.row.formComponentValues[4].value | nullFilter
                  }}
                </p>
              </div>
              <div v-if="scope.row.title.split('的')[1] == '差旅费报销单'">
                <p>出差地点：{{ scope.row.formComponentValues[1].value }}</p>
                <p>出差时间：{{ scope.row.formComponentValues[2].value }}</p>
                <p>所属项目：{{ scope.row.formComponentValues[3].value }}</p>
                <p>出差事由：{{ scope.row.formComponentValues[4].value }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="发起人" prop="">
            <template slot-scope="">
              <p>{{ userInfo.dingTalkDisplayName }}</p>
            </template>
          </el-table-column>
          <el-table-column label="发起时间" prop="createDate">
            <template slot-scope="scope">
              <span v-show="scope.row.createTime">{{
                scope.row.createTime | formatDateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="完成时间" prop="finishTime">
            <template slot-scope="scope">
              <span v-show="scope.row.finishTime">{{
                scope.row.finishTime | formatDateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="流程状态" prop="">
            <template slot-scope="scope">
              <span
                v-if="scope.row.result == 'agree' || scope.row.result == ''"
                :class="{
                  wait: scope.row.status == 'RUNNING',
                  adopt: scope.row.status == 'COMPLETED',
                }"
                >{{
                  scope.row.status == "NEW"
                    ? "新创建"
                    : scope.row.status == "RUNNING"
                    ? "处理中"
                    : scope.row.status == "TERMINATED"
                    ? "已撤销"
                    : scope.row.status == "COMPLETED"
                    ? "审批通过"
                    : scope.row.status == "CANCELED"
                    ? "审批取消"
                    : ""
                }}</span
              >
              <span v-else-if="scope.row.result == 'refuse'">审批拒绝</span>
            </template>
          </el-table-column>
        </el-table>

        <!--  -->
      </div>
    </el-main>

    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
    ></edit>

    <div class="" v-if="drawer">
      <el-drawer
        title="详情"
        size="40%"
        :visible.sync="drawer"
        :direction="direction"
        :destroy-on-close="true"
        @close="closeDrawer"
        :modal-append-to-body="false"
      >
        <div
          class=""
          v-loading="drawerLoading"
          element-loading-text="附件加载中"
        >
          <el-divider></el-divider>
          <div class="top">
            <p class="businessId">编号：{{ processInstanceInfo.businessId }}</p>
            <div class="title">{{ processInstanceInfo.title }}</div>
            <div class="companyNae flexJsbAc">
              <span class="businessId">上海盛旨投资管理有限公司</span>
              <!-- <span v-show="tabIndex == '1'" class="wait">等待我处理</span>
            <span v-show="tabIndex == '2'" class="adopt">已通过</span> -->
              <span
                v-if="
                  processInstanceInfo.result == 'agree' ||
                  processInstanceInfo.result == ''
                "
                :class="{
                  wait: processInstanceInfo.status == 'RUNNING',
                  adopt: processInstanceInfo.status == 'COMPLETED',
                }"
                >{{
                  processInstanceInfo.status == "NEW"
                    ? "新创建"
                    : processInstanceInfo.status == "RUNNING"
                    ? "处理中"
                    : processInstanceInfo.status == "TERMINATED"
                    ? "审批终止"
                    : processInstanceInfo.status == "COMPLETED"
                    ? "审批完成"
                    : processInstanceInfo.status == "CANCELED"
                    ? "审批取消"
                    : ""
                }}</span
              >
              <span v-else-if="processInstanceInfo.result == 'refuse'"
                >审批拒绝</span
              >
            </div>
          </div>
          <div class="all" v-show="processInstanceInfo">
            <el-card class="box-card">
              <div class="one">
                <p class="businessId">所在部门：</p>
                <span>{{ processInstanceInfo.originatorDeptName }}</span>
              </div>
              <div
                class="one"
                v-for="(item, index) in processInstanceInfo.formComponentValues"
                :key="index"
              >
                <div class="sheet" v-if="item.name == '表格'">
                  <div
                    class=""
                    v-for="(sheet, index2) in item.value"
                    :key="index2"
                  >
                    <p class="businessId">{{ item.name }}{{ index2 + 1 }}：</p>
                    <div
                      class="rowValue"
                      v-for="(row, index3) in sheet.rowValue"
                      :key="index3"
                    >
                      <p class="businessId">{{ row.label }}：</p>
                      <span v-show="!Array.isArray(row.value)">
                        {{ row.value }}
                      </span>

                      <span
                        v-show="Array.isArray(row.value)"
                        v-for="(value, index4) in row.value"
                        :key="index4"
                      >
                        {{ value }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="sheet"
                  v-else-if="item.name == '附件' && item.value != null"
                >
                  <p class="businessId">{{ item.name }}：</p>
                  <div
                    class="file"
                    v-for="(file, fileIndex) in item.value"
                    :key="fileIndex"
                  >
                    <p class="businessId" @click="getFileUrl(file)">
                      {{ file.fileName }}
                    </p>
                  </div>
                </div>
                <div
                  class="sheet"
                  v-else-if="item.name == '发票附件' && item.value != null"
                >
                  <p class="businessId">{{ item.name }}：</p>
                  <div class="a" v-if="item.value != 'null'">
                    <div
                      class="file"
                      v-for="(file, fileIndex) in item.value"
                      :key="fileIndex"
                    >
                      <p class="businessId" @click="getFileUrl(file)">
                        {{ file.fileName }}
                      </p>
                    </div>
                  </div>

                  <span v-else>无</span>
                </div>
                <div
                  class="sheet"
                  v-else-if="
                    (item.name == '往返交通费发票附件' ||
                      item.name == '市内交通费发票附件' ||
                      item.name == '住宿费发票附件' ||
                      item.name == '餐费发票附件' ||
                      item.name == '其他费用发票附件' || 
                      item.name == '业务招待费发票附件'  ) &&
                    item.value
                  "
                >
                  <p class="businessId">{{ item.name }}：</p>
                  <div class="a" v-if="item.value">
                    <div
                      class="file"
                      v-for="(file, fileIndex) in item.value"
                      :key="fileIndex"
                    >
                      <p class="businessId" @click="getFileUrl(file)">
                        {{ file.fileName }}
                      </p>
                    </div>
                  </div>

                  <span v-else>无</span>
                </div>
                <div
                  class="sheet"
                  v-else-if="item.name == '图片' && item.value != null"
                >
                  <p class="businessId">{{ item.name }}：</p>
                  <div class="flexJfAc picture">
                    <div
                      class="img"
                      v-for="(picture, pictureIndex) in item.value"
                      :key="pictureIndex"
                    >
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="picture"
                        :preview-src-list="item.value"
                      >
                      </el-image>
                    </div>
                  </div>
                </div>
                <div
                  class="sheet"
                  v-else-if="
                    item.id == 'DDDateRangeField_IIGQMYK8SQ80' &&
                    item.value != null
                  "
                >
                  <p class="businessId">{{ item.name }}：</p>
                  <div class="flexJfAc">
                    <span>{{ item.value[0] }} - {{ item.value[1] }}</span>
                    <!-- <div v-for="(time, timeIndex) in item.value" :key="timeIndex">
                  </div> -->
                  </div>
                </div>
                <div class="else" v-else-if="item.name">
                  <template v-if="item.value != 'null'">
                    <!-- 出差1的行程 -->
                    <template
                      v-if="
                        processInstanceInfo.title.split('的')[1] === '出差1' &&
                        item.name === '行程'
                      "
                    >
                      <template
                        v-for="(tripRow, tripRowIndex) in JSON.parse(
                          item.value
                        ) || []"
                      >
                        <p class="businessRow" :key="'tripRow' + tripRowIndex">
                          行程{{ tripRowIndex + 1 }}
                        </p>
                        <div
                          class="businessContent"
                          v-for="(trip, tripIndex) in tripRow.rowValue"
                          :key="'trip' + tripRowIndex + tripIndex"
                        >
                          <p class="businessId">{{ trip.label }}：</p>
                          <span>{{ trip.value }}</span>
                        </div>
                      </template>
                    </template>
                    <!-- 请假 -->
                    <template
                      v-else-if="
                        processInstanceInfo.title.split('的')[1] === '请假' &&
                        item.name === '开始时间-结束时间'
                      "
                    >
                      <p class="businessId">请假类型</p>
                      <span>{{ item.value[4] }}</span>
                      <p class="businessId">开始时间</p>
                      <span>{{ item.value[0] }}</span>
                      <p class="businessId">结束时间</p>
                      <span>{{ item.value[1] }}</span>
                      <p class="businessId">时长(小时)</p>
                      <span>{{ item.value[2] }}</span>
                    </template>
                    <!-- 外出 -->
                    <template
                      v-else-if="
                        processInstanceInfo.title.split('的')[1] === '外出' &&
                        (item.name === '外出' ||
                          item.name === '开始时间-结束时间')
                      "
                    >
                      <template v-if="item.name === '外出'">
                        <p class="businessId">开始时间</p>
                        <span>{{ item.value[1].value }}</span>
                        <p class="businessId">结束时间</p>
                        <span>{{ item.value[2].value }}</span>
                        <p class="businessId">时长(小时)</p>
                        <span>{{ item.value[3].value }}</span>
                      </template>
                      <template
                        v-if="item.name === '开始时间-结束时间'"
                      ></template>
                    </template>
                    <template v-else>
                      <p class="businessId">{{ item.name }}：</p>
                      <span>{{ item.value }}</span>
                    </template>
                  </template>
                  <template v-else>
                    <p class="businessId">{{ item.name }}：</p>
                    <span>无</span>
                  </template>
                </div>
              </div>
            </el-card>
          </div>
          <div class="drawerFooter">
            <div class="operation flexJsbAc">
              <div class="left">
                <div class="comment flexFJA">
                  <i class="el-icon-chat-round" @click="comment"></i>
                  <span>评论</span>
                </div>
              </div>
              <div class="right">
                <div class="" v-show="tabIndex == '0'">
                  <el-button-group>
                    <el-button
                      size="small"
                      round
                      @click="executeProcessInstance('refuse')"
                      >拒绝</el-button
                    >
                    <el-button
                      size="small"
                      type="primary"
                      @click="executeProcessInstance('agree')"
                      round
                      >同意</el-button
                    >
                  </el-button-group>
                </div>
                <div class="" v-show="tabIndex == '1'">
                  <el-button size="small" class="o0" round>催办</el-button>
                </div>
                <div class="" v-show="tabIndex == '2'">
                  <el-button size="small" class="o0" round>催办</el-button>
                  <el-button
                    size="small"
                    v-show="processInstanceInfo.status != 'TERMINATED'"
                    round
                    @click="terminate"
                    >撤销</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <!-- 文件预览 -->
    <el-dialog
      title="文件预览"
      center
      :visible.sync="previewDialogVisible"
      @close="closePreviewDialog"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
      width="50%"
    >
      <div class="imgUrl" v-if="previewImgUrl">
        <el-image
          style="width: 100%; height: auto"
          :src="previewImgUrl"
          :preview-src-list="previewImgUrlList"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePreviewDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProcessTotoTask,
  getAllProcessInstanceList,
  getProcessInstance,
  executeProcessInstance,
  addProcessInstanceComment,
  getProcessInstanceFileUrl,
  terminateProcessInstance,
  getDownloadUri,
} from "@/api/officeAutomation";

import edit from "@/components/taskTemplate/edit";
import { formatDate } from "@/common/date";

export default {
  name: "oATable",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      buttonControlDialog: false,
      typeDialog: "",
      tableLoading: false,
      total: 100,
      pageNum: 0,
      pageSize: 10,
      pageCount: 1,

      drawer: false,
      direction: "rtl",
      currentRow: null,
      userInfo: null,
      status0: true,
      processInstanceInfo: null,
      processInstanceId: null,
      taskId: null,
      todoTaskQuery: {
        startTime: new Date().getTime() - 3600 * 1000 * 24 * 110,
        endTime: new Date().getTime(),
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      hasMore: true,
      previewDialogVisible: false,
      previewImgUrl: null,
      previewImgUrlList:[],
      drawerLoading: false,
    };
  },
  props: {
    status: String,
    info: Object,
    tabIndex: String,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    titleFilter(title) {
      return title.split("提")[0];
    },
    jsonArrayFilter(arrayString, index) {
      arrayString = arrayString || [];
      let array;
      try {
        array = JSON.parse(arrayString) || [];
      } catch (e) {
        array = [];
      }
      return array[index];
    },
    nullFilter(val) {
      if (!val || val === "null") {
        return "";
      }
      return val;
    },
  },
  components: {
    edit,
  },
  watch: {
    status(val, old) {
      console.log(val);
      if (val != old) {
        console.log(this.status);
      }
    },
    tabIndex(newVal, old) {
      console.log("tabIndex" + newVal);
      if (newVal == 0) {
        this.pageNum = 0;
        this.tableData = [];
        this.getProcessTotoTask(1, this.pageSize);
      } else if (newVal == 1) {
        this.pageNum = 0;
        this.tableData = [];
        this.getProcessTotoTask(1, this.pageSize);
      } else if (newVal == 2) {
        // this.pageNum = 0;
        this.tableData = [];
        this.getProcessTotoTask(1, this.pageSize);
      } else if (newVal == 3) {
      } else if (newVal == 4) {
      }
    },
  },
  //keep-alive的生命周期 ——进入组件
  activated() {
    console.log(this.status);
  },
  //keep-alive的生命周期 ——离开组件
  deactivated() {},
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  },

  mounted() {
    console.log(this.tabIndex);
    this.getProcessTotoTask(0, this.pageSize);
  },

  methods: {
    load() {
      this.pageNum = this.pageNum + this.pageSize;
      this.getProcessTotoTask(this.pageNum, this.pageSize);
    },
    handleExport(item, downloadUri) {
      let fileType = item.fileName.split('.')[1];
      console.log(item)
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        // let url = encodeURIComponent(downloadUri);
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            encodeURI(downloadUri)
        );
      } else if (fileType == "pdf") {
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + downloadUri;
        window.open(encodeURI(url));
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif" || fileType == "JPG" ||
        fileType == "JPEG" ||
        fileType == "PNG" ||
        fileType == "GIF"
      ) {
        this.previewImgUrl = downloadUri;
        this.previewImgUrlList = [];
        this.previewImgUrlList.push(this.previewImgUrl)
        this.previewDialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(encodeURI(downloadUri));
      }
    },
    closePreviewDialog() {
      this.previewImgUrl = null;
      this.previewDialogVisible = false;
    },
    startTimeChange(event) {
      this.tableData = [];
      this.todoTaskQuery.setTime = event;
      this.getProcessTotoTask(0, this.pageSize);
    },
    endTimeChange(event) {
      this.tableData = [];
      this.todoTaskQuery.endTime = event;
      this.getProcessTotoTask(0, this.pageSize);
    },
    comment() {
      console.log(this.processInstanceId);
      this.$prompt("请输入评论", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let info = {
            processInstanceId: this.processInstanceId,
            text: value,
          };
          addProcessInstanceComment(info).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "评论成功!" });
              this.getProcessInstanceDetails(this.processInstanceId);
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消评论",
          });
        });
    },
    getFileUrl(file) {
      this.drawerLoading = true;
      console.log(file);
      getDownloadUri({
        processInstanceId: this.processInstanceId,
        fileId: file.fileId,
        fileName: encodeURI(file.fileName),
      }).then((response) => {
        if (response.success) {
          let data = response.data;
          // console.log(data.downloadUri);
          this.handleExport(file, data.downloadUri);
          //  this.closeDrawer();
          this.drawerLoading = false;
          //  this.$router.push({
          //   name: "officeOnLine", 
          //   params:{file: file, url: data.downloadUri, fileType: file.fileType }
          // });
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
    rowClick(row, column, event) {
      var that = this;
      console.log(row);
      let processInstanceId = that.status0
        ? row.instanceId
        : row.processInstanceId;
      that.processInstanceId = processInstanceId;
      that.taskId = row.taskId;
      that.getProcessInstanceDetails(processInstanceId);
    },
    getProcessInstanceDetails(id) {
      var that = this;
      getProcessInstance(id).then((response) => {
        if (response.success) {
          let data = response.data;
          let formComponentValues = data.formComponentValues;
          //  let file = JSON.parse(JSON.stringify(params.files));
          formComponentValues.forEach((item, index) => {
            if (
              item.name == "表格" ||
              item.name == "附件" ||
              item.name == "图片" ||
              item.name == "发票附件" ||
              item.name == "往返交通费发票附件" ||
              item.name == "市内交通费发票附件" ||
              item.name == "住宿费发票附件" ||
              item.name == "餐费发票附件" ||
              item.name == "其他费用发票附件" ||
              item.name == "业务招待费发票附件"
            ) {
              item.value = JSON.parse(item.value);
            }
            if (item.name == '["开始时间","结束时间"]') {
              item.name = "开始时间-结束时间";
              item.value = JSON.parse(item.value);
            }
            if (item.name == "外出") {
              item.name = "外出";
              item.value = JSON.parse(item.value);
            }
          });
          that.drawer = true;
          that.processInstanceInfo = data;
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
    tableCurrentChange(val) {
      var that = this;

      that.currentRow = val;
    },
    closeDrawer() {
      this.drawerLoading = false;
      this.drawer = false;

      // that
      //   .$confirm("确认关闭？")
      //   .then((_) => {
      //     that.drawer = false;
      //     that.$refs.singleTable.setCurrentRow(that.currentRow);
      //     that.$forceUpdate();
      //     done();
      //   })
      //   .catch((_) => {});
    },
    handleSizeChange(val) {
      this.getProcessTotoTask(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.getProcessTotoTask(val, this.pageSize);
    },
    executeProcessInstance(type) {
      let that = this;
      let msg = type == "agree" ? "同意" : "拒绝";
      that
        .$confirm("确定要" + msg + "此条审批, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: false,
        })
        .then(() => {
          let info = {
            processInstanceId: that.processInstanceId,
            taskId: that.taskId,
            result: type,
          };
          executeProcessInstance(info).then((response) => {
            if (response.success) {
              that.tableData = [];
              that.getProcessTotoTask(0, this.pageSize);
              that.drawer = false;
              that.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              that.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    terminate() {
      this.$confirm("确定要撤销此次审批, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          let data = {
            processInstanceId: this.processInstanceId,
          };
          terminateProcessInstance(data).then((response) => {
            if (response.success) {
              this.tableData = [];
              this.getProcessTotoTask(0, this.pageSize);
              this.drawer = false;
              this.$message({
                type: "success",
                message: "撤销成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    getProcessTotoTask(num, pageSize) {
      this.tableLoading = true;
      if (this.status == 0 || this.status == 1) {
        this.status0 = true;
        let query = {};
        query.offset = num;
        query.count = pageSize;
        query.status = Number(this.status);
        getProcessTotoTask(query).then((response) => {
          if (response.data.list != null) {
            let list = response.data.list;
            this.hasMore = response.data.hasMore;
            this.tableData = this.tableData.concat(list);
            // this.pageNum = response.pageNum;
            // this.pageSize = response.pageSize;
            // this.total = response.total;
            // this.pageCount = response.pageCount;
          }
          this.tableLoading = false;
        });
      } else {
        this.status0 = false;
        let query = {};
        query.startTime = this.todoTaskQuery.startTime;
        query.endTime = this.todoTaskQuery.endTime;
        getAllProcessInstanceList(query)
          .then((response) => {
            if (response.data.list != null) {
              this.tableData = response.data.list;
              // this.pageNum = response.pageNum;
              // this.pageSize = response.pageSize;
              // this.total = response.total;
              // this.pageCount = response.pageCount;
            } else {
              this.total = 0;
            }
          })
          .finally(() => {
            this.tableLoading = false;
          });
      }
    },

    //新增编辑弹窗显示
    openDialog(type, row) {
      // console.log(id);
      if (type == "edit") {
        this.typeDialog = "edit";
        this.info = row;
      } else if (type == "add") {
        this.typeDialog = "add";
      }
      this.editDialog = true;
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.getProcessTotoTask();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  /deep/ .el-table__row {
    cursor: pointer;
  }
}
.el-divider--horizontal {
  margin: 0px 0 15px 0;
}
.top {
  background-color: #fff;
  padding: 0px 20px 10px 20px;
  .businessId {
    font-size: 14px;
    color: #606266;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    margin: 3px 0;
  }
}
.all {
  padding: 5px 10px 60px 10px;
  background-color: rgb(240, 233, 233);

  .box-card {
    margin: 10px 0;
    .one {
      margin: 15px 0;
      p {
        font-size: 13px;
        color: #606266;
        margin: 10px 0 3px 0;
      }
      span {
        font-size: 15px;
      }
      .sheet {
        margin: 25px 0;
        .file {
          cursor: pointer;
        }
        .picture {
          background-color: rgb(243, 239, 239);
          padding: 10px;
          .img {
            margin: 2px 5px;
            img {
              height: auto;
            }
          }
        }
      }
    }
  }
}
.drawerFooter {
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 0px 3px 0 rgba(0, 0, 0, 0.32), 1px 0 0px 0 rgba(0, 0, 0, 0.04);
  .operation {
    width: 100%;
    padding: 0 20px;
    .left {
      .comment {
        cursor: pointer;
        span {
          font-size: 12px;
        }
      }
    }
    .right {
      line-height: 60px;
      text-align: right;
      .o0 {
        opacity: 0;
      }
    }
  }
}
.wait {
  color: darksalmon;
  font-size: 14px;
}
.adopt {
  color: #67c23a;
  font-size: 14px;
}
.terminated {
  color: #666;
  font-size: 14px;
}
.load {
  font-size: 14px;
  color: #666;
  cursor: pointer;
  margin: 10px 0;
}
.businessRow {
  background-color: #eee;
  padding: 5px 5px 5px 20px;
}
.businessContent {
  padding-left: 20px;
}
</style>
